import React from "react";
import Photo from "./Photo";

function areEqual(prevValue, nextValue) {
    // console.table(prevValue)
    // console.table(nextValue)
    // console.log(JSON.stringify(prevValue) === JSON.stringify(nextValue))
    return JSON.stringify(prevValue) === JSON.stringify(nextValue);
}

const PhotoSelection = ({
    myPhotos,
    selectedImages,
    selectImage,
    removeImage,
    currentStep,
    setCurrentStep,
    checkIfAPhotoIsSelected,
    checkIfAlreadySelected,
    removeSelectedImage,
}) => {
    let isCurrentStep = currentStep === 0;
    function handleCurrentStepChange() {
        setCurrentStep(0);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    return (
        <>
            <section
                className={`onHidden ${isCurrentStep ? "hide" : "show"}`}
                onClick={handleCurrentStepChange}
            >
                <p>Select your images</p>
                <input
                    type="button"
                    className="action c-default"
                    value="+"
                    onClick={handleCurrentStepChange}
                />
            </section>
            <section
                className={`step imageSelectorContainer  ${
                    isCurrentStep ? "show" : "hide"
                }`}
            >
                <section
                    className="imageContainer"
                    // style={{ visibility: currentStep === 1 ? 'visible' : 'hidden' }}
                >
                    {myPhotos.length > 0 && <h4>Available Images</h4>}
                    {myPhotos.length > 0 ? (
                        myPhotos.map((obj) => {
                            let isSelected = checkIfAlreadySelected(obj.id);
                            return (
                                <article
                                    className="imageContainer"
                                    key={obj.id}
                                >
                                    <Photo
                                        className="image"
                                        key={obj.id}
                                        base64={obj.base64}
                                        alt="Selectable"
                                    />
                                    {!obj.isSelected && !isSelected ? (
                                        <section className="buttonsContainer">
                                            <input
                                                type="button"
                                                value="Select"
                                                className="action c-green"
                                                data-image-operation={true}
                                                onClick={() => selectImage(obj)}
                                            />
                                            <input
                                                type="button"
                                                value="Delete"
                                                className="action c-red"
                                                onClick={() => removeImage(obj)}
                                            />
                                        </section>
                                    ) : (
                                        <p>Selected</p>
                                    )}
                                </article>
                            );
                        })
                    ) : (
                        <p className="allSelected">
                            You selected all the available images
                        </p>
                    )}
                </section>
                <section className="imageContainer selectedImagesContainer">
                    {selectedImages.length > 0 && <h4>Selected Images</h4>}
                    {selectedImages &&
                        selectedImages.map((obj) => (
                            <article className="imageContainer" key={obj.id}>
                                <input
                                    type="button"
                                    value="Remove"
                                    className="action c-default"
                                    onClick={() => removeSelectedImage(obj)}
                                />
                                <Photo
                                    className="image"
                                    key={obj.id}
                                    base64={obj.base64}
                                    alt="Selected"
                                />
                            </article>
                        ))}
                </section>
            </section>
            {currentStep === 0 && (
                <input
                    type="button"
                    className="action c-default"
                    value="Continue"
                    onClick={checkIfAPhotoIsSelected}
                />
            )}
        </>
    );
};
export default React.memo(PhotoSelection, areEqual);
