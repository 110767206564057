const LoadingScreen = ({ isLoading }) => {
    const body = document.getElementsByTagName('body')[0]
    if(isLoading) body.style.overflowY = 'hidden';
    else body.style.overflowY = 'scroll';

    return(
        <div className={`preloaderWrapper ${isLoading ? "" : "loaded"}`}>
            <div className="preloaderScreen">
                <div className="loadingSpinnerWrap">
                    <div className="loadingSpinner"></div>
                </div>
            </div>
        </div>
    )
}

export default LoadingScreen;
