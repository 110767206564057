import Paginator from "../../genericComponents/Paginator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import React, {useState} from "react";
import {Button, Card, Collapse} from "react-bootstrap";
import {ToastContainer} from "react-toastify";
import {selectDropdown} from "../../../css/admin/photoUpload.module.scss";
import CompanyDropdown from "./CompanyDropdown";
const Invitation = ({
    handleScroll,
    handleEmail,
    handleEnterPress,
    invitations,
    handlePendingInvSelection,
    pendingInvitation,
    modifyInvitation,
    setInvitations,
    selectedCompany,
    handleCompanySelection,
    companies,
    setCompanies
}) => {
    let isInvitations = invitations.data && invitations.data.length > 0;
    const [isOpen, setIsOpen] = useState(true);

    function resendInvitation() {
        modifyInvitation(true)
    }
    function revokeInvitation() {
        modifyInvitation(false)
    }

    const displayInvitations = () => {
        let options = invitations.data.map((inv) => {
            return (
                <option key={inv.id}>
                    {inv.sent_to}
                </option>
            )
        });
        return (
            <section className="pending">
                <p className="bold">Pending invitations</p>
                <div className={selectDropdown}>
                    <select
                        onScroll={handleScroll}
                        onChange={handlePendingInvSelection}>
                        <option disabled selected default>Select an invite to revoke/resend</option>
                        {options}
                    </select>
                </div>
                {pendingInvitation &&
                    <>
                        <input type="button" value="Resend" className="action mr-2 mb-2" onClick={resendInvitation} />
                        <input type="button" value="Revoke" className="action" onClick={revokeInvitation} />
                    </>
                }
                {invitations.per_page < invitations.total &&
                    <Paginator
                        links={invitations.links}
                        currentPage={invitations.current_page}
                        last_page={invitations.last_page}
                        setHandler={setInvitations}
                    />
                }
            </section>
        )
    }

    return (
        <section className="admin">
            <ToastContainer />
            <Card>
                <Button
                    variant="secondary"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    Invitations {isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </Button>
                <Collapse in={isOpen}>
                    <Card.Body>
                        {companies &&
                            <CompanyDropdown
                                selectedCompany={selectedCompany}
                                handleCompanySelection={handleCompanySelection}
                                companies={companies}
                                setHandler={setCompanies}
                            />
                        }
                        <section className="invitationContainer">
                            <label htmlFor="sendInvitation">Send invitation within selected company:</label>
                            <input
                                id="inviteButton"
                                name="sendInvitation"
                                placeholder="Enter email address"
                                type="text"
                                style={{width:'100%'}}
                                onChange={handleEmail}
                                // pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                                onKeyPress={handleEnterPress}
                                required
                            />
                            <div className="my-3">
                                <input value="Send" type="submit" className="action" onClick={handleEnterPress} />
                            </div>
                        </section>
                        <section className="pendingInvitation">
                            {isInvitations && displayInvitations()}
                        </section>
                    </Card.Body>
                </Collapse>
            </Card>
        </section>
    )
}

export default Invitation;
