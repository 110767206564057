import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../../assets/logo.png";
import Socials from "../socials/Socials";
import './footer.scss';

function Footer() {
    return (
        <footer id="footer">
            <section className="top">
                <section className="afs">
                    <img src={logo} alt="Art Force Studio" />
                    <article className="textContainer">
                        <p>More than 15 years of experience</p>
                        <p>Photo and videography services all around the world</p>
                        <p>Portrait and commercial services in Nottinghamshire.</p>
                    </article>
                    <Socials />
                </section>
                <article>
                    <section className="services">
                        <h6 className="subTitle">Services</h6>
                        <NavLink to="/photography/photoshop">Visual Effects</NavLink>
                        <NavLink to="/photography/outdoors">Off Studio</NavLink>
                    </section>
                    <section className="links">
                        <h6 className="subTitle">Quick links</h6>
                        <NavLink to="/about">About Us</NavLink>
                        <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
                        <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                        <NavLink to="/contact">Contact Us</NavLink>
                    </section>
                </article>
            </section>
            <div className="divider"></div>
            <section className="bottom">
                <section className="poweredBy">
                    <p>© 2021 Art Force Studios. All Rights Reserved.</p>
                    <article>
                        <p>Powered by</p>
                        <a href="https://www.lumem.co.uk" target="_blank" rel="noreferrer noopener">
                            Lumem
                        </a>
                    </article>
                </section>
            </section>
        </footer>
    );
}

export default Footer;