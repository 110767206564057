import { useState, useRef, useContext, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import useLocalStorage from "../../helpers/useLocalStorage";
import useSessionStorage from "../../helpers/useSessionStorage";
import { callApi } from "../../services/apiService";
import { AuthContext } from "../../components/contexts/AuthContext";
import { LanguageContext } from "../../components/contexts/LanguageContext";
import CartLogic from "../cartComponents/CartLogic";
import "react-confirm-alert/src/react-confirm-alert.css";
import responseHandler from "../../helpers/responseHandler";

const ShopLogic = () => {
    const [packageInfo, setPackageInfo] = useState();
    // const [orderId, setOrderId] = useState();
    const [validationMessages, setValidationMessages] = useState([]);
    const [myPhotos, setMyPhotos] = useState();
    const imageRef = useRef([]);
    const { getToken, signOut } = useContext(AuthContext);
    const languageContext = useContext(LanguageContext);
    const { total_price } = CartLogic();

    useEffect(() => {
        async function fetchPackages() {
            const additionalHeaders = {
                "Content-Language": languageContext.languageState,
                Authorization: "Bearer " + getToken(),
            };
            callApi("/packages", additionalHeaders)
                .then((res) => {
                    if (res.data.success) {
                        setPackageInfo(res.data.data);
                    }
                })
                .catch(({response}) => {
                    if(response.status === 401){
                        responseHandler('error', response.data)
                        signOut();
                    }
                });
        }

        !packageInfo && fetchPackages();
    }, []);

    useEffect(() => {
        async function fetchMyPhotos() {
            const additionalHeaders = {
                "Content-Language": languageContext.languageState,
                Authorization: "Bearer " + getToken(),
            };
            callApi("/my-photos", additionalHeaders)
                .then((res) => {
                    if(res.data.success) {
                        let photos = res.data.files.map((file) => {
                            return { ...file, isSelected: false };
                        });
                        setMyPhotos(photos);
                    }
                })
                .catch(({response}) => {
                    if (response.status === 401) {
                        responseHandler('error', response.data)
                        signOut();
                    }
                });
        }

        !myPhotos && fetchMyPhotos();
    }, []);

    const handleApprove = (data, actions) => {
        const additionalHeaders = {
            "Content-Language": languageContext.languageState,
            Authorization: "Bearer " + getToken(),
        };
        const body = {
            pp_order_id : data.orderID,
            payment_gateway_id: 1,
        }
        return callApi("/paypal/order/capture", additionalHeaders, "post", body)
            .then(({data}) => {
                // console.log(res.json());
                let transaction = data.purchase_units[0].payments.captures[0];
            });
    }

    const postOrder = async () => {
        const additionalHeaders = {
            "Content-Language": languageContext.languageState,
            Authorization: "Bearer " + getToken(),
        };
        // const orderBody = {
        //     ...deliveryAddress,
        //     ...selectedPackages,
        // };
        const totalPrice = total_price(selectedPackages);
        const packages = { packages: selectedPackages };
        const price = { price: totalPrice };
        const orderBody = Object.assign(
            {},
            deliveryAddress,
            packages,
            price
            // "packages": selectedPackages,
        );
        //createPaypalOrder('e7dcfbe1-a1d3-4856-945e-9aee1e4e6625')
        //temporarily commented out
        return await callApi("/order", additionalHeaders, "post", orderBody)
            .then((response) => {
                let { data } = response.data;
                let orderId = data.id;
                return orderId;
                //createPaypalOrder(orderId);
                // setOrderId(response.data.id)
                //responseHandler("success", response.data)
            })
            .catch((err) => console.log(err));
        // console.log("after await", test);
        // await createPaypalOrder(test);
        // console.log("after paypal");
    };

    const popOut = (url) => {
        let newWindow = window.open(url,'name','height=600,width=400');
        if (window.focus) {
            newWindow.focus()
        }
        return false;
    }


    const createPaypalOrder = async (orderId) => {
        const additionalHeaders = {
            "Content-Language": languageContext.languageState,
            Authorization: "Bearer " + getToken(),
        };

        let body = { orderId }
        return await callApi("/paypal/order/create", additionalHeaders, "post", body)
        // return await callApi("/paypal/order/create", additionalHeaders, "post", body)
            // .then((response) => {
            //     console.log(response);
            //     return response.json();
            //     // window.open(href,"windowName",'height=200,width=150');
            //     // window.open(href, "_blank");
            // })
            // .then((response) => {
            //     console.log(response.data);
            //     let {links, id} = response.data;
            //     let link = links.filter(link => {
            //         return link.rel === "approve"
            //     })[0];
            //     //let {href} = link;
            //     //popOut(href);
            //     return id;
            // })
            // .catch((err) => console.log(err));
    }

    const [deliveryAddress, setDeliveryAddress] = useSessionStorage(
        "deliveryAddress",
        {}
    );

    const [currentStep, setCurrentStep] = useLocalStorage("currentStep", 0);
    const [selectedImages, setSelectedImages] = useLocalStorage(
        "selectedImages",
        []
    );
    const [selectedPackages, setSelectedPackages] = useLocalStorage(
        "selectedPackages",
        []
    );
    const [potentialPackages, setPotentialPackages] = useLocalStorage(
        "potentialPackages",
        []
    );

    function checkIfAPhotoIsSelected() {
        if (selectedImages.length > 0) nextStep();
        else
            console.log(
                "Display message here saying that theres no selected image"
            );
    }

    const checkIfAlreadySelected = (id) => {
        if (selectedImages) {
            let hasValues = Object.keys(selectedImages).length > 0;
            if (hasValues)
                return selectedImages.find((x) => x.id === id) ?? false;
        }
        return false;
    };

    const _removePackage = (packageObj) => {
        let { id } = packageObj;
        let updatedPackages = selectedPackages.filter((pack) => pack.id !== id);

        setSelectedPackages(updatedPackages);
    };

    const removePackage = (packageObj) => {
        let { name } = packageObj;
        confirmAlert({
            title: `Confirm to remove ${name} from your cart`,
            message: `Are you sure you want to remove ${name}?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => _removePackage(packageObj),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    function splitContentToPackages(contents, arraySize) {
        return contents
            .map((e, i) => {
                return i % arraySize === 0
                    ? contents.slice(i, i + arraySize)
                    : null;
            })
            .filter((e) => e);
    }

    const selectPackage = (e, packageItem) => {
        let { name, id, price_gbp, items_required, images } = packageItem;
        let isPotential = potentialPackages.some((pack) => pack.id === id);
        let item12by8 = packageItem.choosable_items.filter(
            (item) => item.item.id === 1
        )[0];
        let numberOf12by8 = item12by8?.quantity;
        let hasValues = validationMessages.length > 0;
        let isSingular = packageItem.id >= 5 && packageItem.id <= 8;
        if (!isPotential && !hasValues) {
            if (!isSingular) {
                setValidationMessages([
                    ...validationMessages,
                    {
                        packageId: id,
                        validationId: 1,
                        message: `You need to customise your package first. Select colour${
                            item12by8 ? " and size." : "."
                        }`,
                    },
                ]);
                return false;
            } else {
                selectedImages.map((image) => {
                    setSelectedPackages((prevValue) => [
                        ...prevValue,
                        {
                            id,
                            largeImages: [],
                            contents: [
                                {
                                    photo_id: image.id,
                                    isColour: true,
                                },
                            ],
                            price: price_gbp,
                            multiplier: 1,
                            name,
                        },
                    ]);
                    return true;
                });
                return true;
            }
        } else {
            let removeFirst = validationMessages.filter(
                (validation) => validation.validationId !== 1
            );
            let currentPackage = potentialPackages.filter(
                (pack) => pack.id === id
            )[0];
            if (currentPackage.contents.length < items_required) {
                let hasValidation = validationMessages.some(
                    (validation) => validation.validationId === 2
                );
                if (!hasValidation) {
                    setValidationMessages([
                        ...removeFirst,
                        {
                            packageId: id,
                            validationId: 2,
                            message:
                                "You need to select colour/black and white for every photo.",
                        },
                    ]);
                    return false;
                }
                // responseHandler("error", { message: "You need to select colour/black and white for every photo." })
                return false;
            }

            if (currentPackage.largeImages.length < numberOf12by8) {
                let hasValidation = validationMessages.some(
                    (validation) => validation.validationId === 3
                );
                if (!hasValidation) {
                    setValidationMessages([
                        ...removeFirst,
                        {
                            packageId: id,
                            validationId: 3,
                            message:
                                "Select which photos you'd like to order in 12x8.",
                        },
                    ]);
                    return false;
                }
                // responseHandler("error", { message: "Select which photos you'd like to order in 12x8." })
                return false;
            }

            if (images === 2) {
                if (currentPackage.contents.length % images !== 0) {
                    let hasValidation = validationMessages.some(
                        (validation) => validation.validationId === 4
                    );
                    if (!hasValidation) {
                        setValidationMessages([
                            ...removeFirst,
                            {
                                packageId: id,
                                validationId: 3,
                                message: `${
                                    images > currentPackage.contents.length
                                        ? "Too many images selected"
                                        : "You need to select more images"
                                } `, //" photos you'd like to order in 12x8."
                            },
                        ]);
                        return false;
                    }
                    // responseHandler("error", { message: "Select which photos you'd like to order in 12x8." })
                    return false;
                }
            }

            if (currentPackage.largeImages.length === numberOf12by8) {
                let resetValidation = validationMessages.filter(
                    (validation) => validation.validationId !== 3
                );
                setValidationMessages(resetValidation);
            }

            if (currentPackage.contents.length === items_required) {
                let resetValidation = validationMessages.filter(
                    (validation) => validation.validationId !== 2
                );
                setValidationMessages(resetValidation);
            }
        }

        // need to get colours too
        // find if in potentials

        // let isInSelectedAlready = selectedPackages.filter((pack) => pack.id === packageId);
        // Should only return one as its ID checking
        // if (!isInSelectedAlready) {

        let isInPotential = potentialPackages.filter(
            (pack) => pack.id === id
        )[0];
        let { contents } = isInPotential;
        if (contents.length > 1 && images === 1) {
            let splitPackages = splitContentToPackages(contents, images);

            splitPackages.map((pack) => {
                setSelectedPackages((prevValue) => [
                    ...prevValue,
                    {
                        id,
                        largeImages: isInPotential.largeImages,
                        contents: pack,
                        price: price_gbp,
                        name,
                    },
                ]);
            });
        } else {
            if (isInPotential) {
                setSelectedPackages((prevValue) => [
                    ...prevValue,
                    {
                        id,
                        largeImages: isInPotential.largeImages,
                        contents: isInPotential.contents,
                        price: price_gbp,
                        name,
                    },
                ]);
            }
        }
        let resetValidation = validationMessages.filter(
            (validation) => validation.packageId !== id
        );
        let removePackageFromPotential = potentialPackages.filter(
            (pack) => pack.id !== id
        );

        setPotentialPackages(removePackageFromPotential);
        setValidationMessages(resetValidation);
        resetPackage(e, id);

        return true;
    };

    const removeSelectedImage = (imageObj) => {
        let { id } = imageObj;
        let updatedImages = selectedImages.filter((image) => image.id !== id);
        let updatedArray = myPhotos.map((image) =>
            parseInt(image.id) === parseInt(id)
                ? { ...image, isSelected: false }
                : image
        );

        setSelectedImages(updatedImages);
        setMyPhotos(updatedArray);
    };

    const packageExists = (packageId) =>
        potentialPackages.some((el) => el.id === packageId);

    const resetPackage = (e) => {
        let container = e.target.parentElement;
        let packageContainer = container.parentElement;
        let selectedItems = packageContainer.getElementsByClassName("selected");
        Array.from(selectedItems).forEach((item) => {
            let selected = item.classList.contains("selected");
            if (selected) {
                item.classList.remove("selected");
            }
        });
        return;
    };

    const select12by8 = (e, imageId, packageId) => {
        let selectedPackage = packageInfo.data.filter(
            (pack) => pack.id === packageId
        )[0];
        let item12by8 = selectedPackage.choosable_items.filter(
            (item) => item.item.id === 1
        )[0];
        let numberOf12by8 = item12by8.quantity;
        let colourSelector = e.target.parentElement;
        let imageContainer = colourSelector.parentElement;
        let container = imageContainer.parentElement;
        let selectors = container.getElementsByClassName("sizeSelector");
        let selectedItems = Array.from(selectors).filter((selector) =>
            selector.classList.contains("selected")
        );
        let numberOfSelectedItems = selectedItems.length;

        if (numberOfSelectedItems === numberOf12by8) {
            e.target.classList.remove("selected");

            Array.from(selectors).forEach((selector) => {
                let notSelected = !selector.classList.contains("selected");
                if (notSelected) {
                    selector.style.pointerEvents = "auto";
                }
            });
        } else {
            if (e.target.classList.contains("selected")) {
                //Remove from array
                e.target.classList.remove("selected");
            } else {
                //Add to array
                e.target.classList.add("selected");
                if (numberOfSelectedItems + 1 === numberOf12by8) {
                    Array.from(selectors).forEach((selector) => {
                        let notSelected =
                            !selector.classList.contains("selected");
                        if (notSelected) {
                            selector.style.pointerEvents = "none";
                        }
                    });
                }
            }
        }

        let packageAlreadyExists = packageExists(packageId);
        if (packageAlreadyExists) {
            if (e.target.classList.contains("selected")) {
                let updatedPackages = potentialPackages.map((pack) =>
                    pack.id === packageId
                        ? {
                              ...pack,
                            largeImages: [...pack.largeImages, { photo_id: imageId }],
                          }
                        : pack
                );
                setPotentialPackages([updatedPackages[0]]);
            } else {
                let updatedPackages = potentialPackages.map((pack) => {
                    if (pack.id === packageId) {
                        let images = pack.largeImages.filter(
                            (image) => image.photo_id !== imageId
                        );
                        return { ...pack, largeImages: images };
                    }
                    return pack;
                });
                setPotentialPackages(updatedPackages);
            }
        } else {
            setPotentialPackages([
                ...potentialPackages,
                {
                    id: packageId,
                    contents: [],
                    largeImages: [{ photo_id: imageId }],
                },
            ]);
        }

        // check how many 12x8 is in package
        // check if any other one is selected
        // if limit is not reached, select new
        // if limit is reached, unselect other one, select new
    };

    const selectColour = (e, imageId, packageId) => {
        let isColour = e.target.name !== "colour"; // Using !== as display is not async

        //Will always be the first child
        let colourBox = e.target.parentNode.firstChild;

        //Will always be the last child
        let blackAndWhiteBox = e.target.parentNode.lastChild;
        if (colourBox) {
            let wasSelected = colourBox.classList.contains("selected");
            if (wasSelected) colourBox.classList.remove("selected");
        }
        if (blackAndWhiteBox) {
            let wasSelected = blackAndWhiteBox.classList.contains("selected");
            if (wasSelected) blackAndWhiteBox.classList.remove("selected");
        }
        e.target.classList.add("selected");

        let packageAlreadyExists = packageExists(packageId);
        if (packageAlreadyExists) {
            setPotentialPackages((prevValue) => {
                return prevValue.map((el) => {
                    let correctPackage = el.id === packageId;
                    let contents;
                    let newContent = el.contents;
                    let loopedThrough = false;
                    if (correctPackage) {
                        if (el.contents) {
                            contents = el.contents.map((el) => {
                                if (el.photo_id === imageId) {
                                    loopedThrough = true;
                                    return { ...el, isColour };
                                }
                                return el;
                            });
                            if (!loopedThrough) {
                                newContent.push({
                                    photo_id: imageId,
                                    isColour,
                                });
                            }
                        }
                        if (!loopedThrough) {
                            return { ...el, contents: newContent };
                        }
                    }
                    return el.id === packageId ? { ...el, contents } : el;
                });
            });
        } else {
            setPotentialPackages([
                ...potentialPackages,
                {
                    id: packageId,
                    contents: [
                        {
                            photo_id: imageId,
                            isColour,
                        },
                    ],
                    largeImages: [],
                },
            ]);
        }
    };

    const selectImage = (imageObj) => {
        let { id, base64 } = imageObj;
        let isImageAlreadySelected = checkIfAlreadySelected(id);
        if (!isImageAlreadySelected) {
            setSelectedImages((prevValue) => [
                ...prevValue,
                {
                    id,
                    base64
                },
            ]);
        }
        removeImage(imageObj);
    };
    const removeImage = (imageObj) => {
        let { id } = imageObj;
        if (typeof id === "string") id = parseInt(id);
        let updatedArray = myPhotos.map((obj) =>
            obj.photo_id === id ? { ...obj, isSelected: true } : obj
        );
        setMyPhotos(updatedArray);
        // setDataFromApi(dataFromApi.filter(x => x.imageId !== imageId && x.isSelected = true));
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const prevStep = () => {
        let steps = document.getElementsByClassName("step");
        let stepToShow = steps[currentStep - 1].offsetTop;
        window.scrollTo({ top: stepToShow, behaviour: "smooth" });
        if (currentStep > 0) setCurrentStep(currentStep - 1);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        //console.log(deliveryAddress);
    };

    const handleInput = (e) => {
        let inputName = e.target.name;
        let value = e.target.value;
        setDeliveryAddress({
            ...deliveryAddress,
            [inputName]: value,
        });
    };

    // if (!isLoading) {
    return {
        deliveryAddress,
        myPhotos,
        selectedImages,
        // changeImageQuantity,
        currentStep,
        imageRef,
        setCurrentStep,
        packageInfo,
        selectedPackages,
        validationMessages,
        createPaypalOrder,
        handleApprove,
        postOrder,
        handleFormSubmit,
        handleInput,
        selectImage,
        removeImage,
        nextStep,
        prevStep,
        checkIfAPhotoIsSelected,
        selectColour,
        selectPackage,
        select12by8,
        removeSelectedImage,
        removePackage,
        checkIfAlreadySelected,
    };
    // }
};
export default ShopLogic;
