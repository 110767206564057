import React, {useContext, useEffect, useState} from "react";
import LoadingScreen from "../components/loading-screen/LoadingScreen"
import {DetailsContext} from "../components/contexts/DetailsContext";

function Privacy() {
    const [isLoading, setIsLoading] = useState(() => setTimeout(() => setIsLoading(false), 1000))
    const detailsContext = useContext(DetailsContext);
    const {pageContents} = detailsContext;
    const [currentPageContent, setCurrentPageContent] = useState(null);

    useEffect(() => {
        let pageContent = pageContents.filter(page => page.page === 'privacy-policy')[0];
        setCurrentPageContent(pageContent);
    }, [])

    return (
        <>
            <LoadingScreen isLoading={isLoading} />
            <main className="privacy">
                {
                    currentPageContent?.contents.length > 0 &&
                    currentPageContent.contents.map(section => {
                        return (
                            <>
                                <h4>{section.section_title}</h4>
                                <p>{section.section_content}</p>
                            </>
                        )
                    })
                }
            </main>
        </>
    );
}

export default Privacy;
