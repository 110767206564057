import React, {
    useState,
    useReducer,
    useMemo,
    useEffect,
} from "react";
import { Router as RouterContainer } from "react-router-dom";
import Header from "./components/header/Header";
import Router from "./components/router/Route";
import AdminRouter from "./components/router/AdminRoute";
import Footer from "./components/footer/Footer";
import { LanguageContext } from "./components/contexts/LanguageContext";
import { AuthContext } from "./components/contexts/AuthContext";
import { DetailsContext } from "./components/contexts/DetailsContext";
import { languageReducer } from "./components/reducers/LanguageReducer";
import { loginReducer } from "./components/reducers/LoginReducer";
import history from "./components/router/history";
import responseHandler from "./helpers/responseHandler";
import { callApi } from "./services/apiService";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/app.scss";

function App() {
    const [userObject, setUserObject] = useState({});
    const [albumNames, setAlbumNames] = useState([]);
    const [videoNames, setVideoNames] = useState([]);
    const [pageContents, setPageContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const initialLoginState = {
        username: null,
        accessToken: null,
    };

    const authContext = useMemo(
        () => ({
            isAdmin: () => {
                try {
                    let user = localStorage.getItem("user");
                    if (user !== null) {
                        let userObj = JSON.parse(user);
                        return (
                            userObj.role === "admin" || userObj.role === "super"
                        );
                    } else return false;
                } catch (e) {
                    console.log("error is: ", e);
                    return false;
                }

                //false//userObject.role === 'user',
            },
            isUserLoggedIn: userObject,
            getToken: () => {
                try {
                    let token = localStorage.getItem("accessToken");
                    if (token !== null) return token;
                    return null;
                } catch (e) {
                    console.log("error is: ", e);
                }
            },
            getUser: () => {
                try {
                    let user = localStorage.getItem("user");
                    if (user !== null) {
                        let userObj = JSON.parse(user);
                        return userObj;
                    } else return null;
                } catch (e) {
                    console.log("error is: ", e);
                }
            },
            signIn: (user, token) => {
                if (user && token) {
                    try {
                        localStorage.setItem("user", JSON.stringify(user));
                        localStorage.setItem("accessToken", token);
                        setUserObject(user);
                    } catch (e) {
                        console.log("error is: ", e);
                    }
                    dispatch({
                        type: "LOGIN",
                        id: user.username,
                        token: token,
                    });
                }
            },
            signOut: () => {
                try {
                    localStorage.removeItem("user");
                    localStorage.removeItem("accessToken");
                    setUserObject({});
                    history.push("/");
                } catch (e) {
                    console.log("error is: ", e);
                }
                dispatch({ type: "LOGOUT" });
            },
        }),
        [userObject]
    );

    // Set default value to hungarian - this will be overriden by localstorage value
    const [language, languageDispatch] = useReducer(languageReducer, "en");

    const getVideoNames = async() => {
        const additionalHeaders = {
            "Content-Language": language,
        };
        await callApi("/videoalbums", additionalHeaders)
            .then((response) => {
                setVideoNames(response.data);
            })
            .catch(({ response }) => {
                responseHandler("error", response?.data);
            });
    };

    const getAlbumNames = async () => {
        const additionalHeaders = {
            "Content-Language": language,
        };
        await callApi("/albums", additionalHeaders)
            .then((response) => {
                setAlbumNames(response.data);
            })
            .catch(({ response }) => {
                responseHandler("error", response?.data);
            });
    };

    const getPages = async () => {
        await callApi('/pages/all')
            .then(({data}) => {
                setPageContents(data.data);
            })
            .catch(({response}) => {
                responseHandler('error', response?.data)
            });
    };

    async function runAsync() {
        await getAlbumNames();
        await getVideoNames();
        await getPages();
        setIsLoading(false);
    }

    // Get album, video names and pages on load
    useEffect(() => {
        runAsync();
    }, []);

    const [, dispatch] = useReducer(loginReducer, initialLoginState);

    const detailsContext = useMemo(() => ({
        albumNames,
        videoNames,
        pageContents
    }));

    return isLoading ? (
        <div className="loading"></div>
    ) : (
        <LanguageContext.Provider
            value={{ languageState: language, languageDispatch }}
        >
            <AuthContext.Provider value={authContext}>
                <DetailsContext.Provider value={detailsContext}>
                    <RouterContainer history={history}>
                        <section
                            className={
                                authContext.isAdmin() ? "adminPanel" : null
                            }
                        >
                            <Header />
                            {authContext.isAdmin() ? (
                                <AdminRouter />
                            ) : (
                                <Router />
                            )}
                            <Footer />
                        </section>
                    </RouterContainer>
                </DetailsContext.Provider>
            </AuthContext.Provider>
        </LanguageContext.Provider>
    );
}

export default App;
