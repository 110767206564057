import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Photo from "./Photo";
import PackageItems from "./PackageItems";
import {faPlusSquare} from "@fortawesome/free-solid-svg-icons/faPlusSquare";

const PackageSelection = ({
    prevStep,
    nextStep,
    currentStep,
    packageInfo,
    selectedImages,
    selectColour,
    selectPackage,
    selectedPackages,
    setCurrentStep,
    select12by8,
    validationMessages,
}) => {
    let isCurrentStep = currentStep === 1;

    let selectingPackage = useRef(null);

    function handleCurrentStepChange() {
        setCurrentStep(1);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    const getRemaining = (item, imageCount) => {
        let multiplier = item.quantity === imageCount ? 1 : item.quantity;
        let remaining = [];
        for (let i = 0; multiplier > i; i++){
            let images = selectedImages.map((photo) => {
                return <Photo key={photo.id} base64={photo.base64}/>
            })
            remaining.push(images);
        }
        return remaining;
    }

    const displayRemainingItems = (packageItem) => {
        let { choosable_items, images } = packageItem;
        if(images - selectedImages.length > 0)
            return;
        let hasBigImages = choosable_items.some(item => item.item.id === 2 || item.item.id === 1)
        let items = choosable_items.filter(item => {
            if(hasBigImages){
                return item.item.id !== 2 && item.item.id !== 1
            }
            return item.item.id !== 2 && item.item.id !== 1 && item.item.id !== 4
        })

        let remaining = items.map((item) => {
            return (
                <section className="remaining">
                    {getRemaining(item, images)}
                </section>
            )
        })

        return (
            <section className="remainingImages">
                {remaining}
            </section>
        )
        // return (
        //     <section className="remainingImages">
        //
        //         <section className="byEight">{images}</section>
        //         <section className="byFour">{images}</section>
        //     </section>
        // );
    }

    const getImages = (packageItem, isDisabled) => {
        let { choosable_items } = packageItem;
        let packageId = packageItem.id;
        let hasColourSelection = packageId < 5;
        let hasSelection = packageId < 7;
        let has12x8 = choosable_items.filter((item) => item.item.id === 1)[0];
        let imagesArray = selectedImages.map((photo) => (
            <article key={photo.base64} className="colourAndQuantityContainer">
                {hasColourSelection && !isDisabled && (
                    <section className="colourSelector">
                        <input
                            type="button"
                            name="blackAndWhite"
                            onClick={(e) =>
                                selectColour(e, photo.id, packageId)
                            }
                            className="colourOption box"
                        />
                        {has12x8 && (
                            <input
                                className="sizeSelector action c-default"
                                type="button"
                                value="12x8"
                                onClick={(e) =>
                                    select12by8(e, photo.id, packageId)
                                }
                            />
                        )}
                        <input
                            type="button"
                            name="colour"
                            onClick={(e) =>
                                selectColour(e, photo.id, packageId)
                            }
                            className="colourOption box"
                        />
                    </section>
                )}
                {hasSelection && <Photo key={photo.id} base64={photo.base64} />}

            </article>
        ));

        for (let i = 0; i < packageItem.images - selectedImages.length; i++) {
            imagesArray.push(
                <article className="colourAndQuantityContainer" key={i}>
                    <p className="notice">
                        Select another photo to select this package
                    </p>
                    <FontAwesomeIcon
                        icon={faPlusSquare}
                        className="addPhoto"
                        onClick={prevStep}
                    />
                </article>
            );
        }

        return imagesArray;
    };

    const selectPackageAndAnimate = (e, packageItem, multiplier, key) => {
        let success = selectPackage(e, packageItem, multiplier);
        if (success) {
            selectingPackage.current = key;
        }
    };

    const displayValidations = (packageId) => {
        if (validationMessages.length > 0) {
            return validationMessages.map((validation) => {
                if (validation.packageId === packageId) {
                    return (
                        <p
                            className="validation message"
                            key={validation.packageId}
                        >
                            {validation.message}
                        </p>
                    );
                }
            });
        }
    };

    function calculateMultiplier(packageItem, division) {
        const { images, is_per_image } = packageItem;
        if (images || !!is_per_image) {
            division = selectedImages.length / images;
            return {
                multiplier: Number.isInteger(division)
                    ? division
                    : selectedImages.length - packageItem.images,
                division,
            };
        }
        return {
            multiplier: 1,
            division,
        };
    }

    const displayPackages = () => {
        let isOnTop;
        let isDisabled;
        let multiplier;
        let division;
        let numberOfItems;
        let sendingToCart;
        // let hasValidation;
        let packages = packageInfo.data.map((packageItem) => {
            multiplier = 0;
            // freeItems = [];
            // nonFreeItems = [];
            let multiResult = calculateMultiplier(packageItem);
            multiplier = multiResult.multiplier;
            division = multiResult.division;

            // hasValidation = validationMessages.filter(validation => validation.packageId === packageItem.id).length
            numberOfItems = selectedImages.length - packageItem.images;
            isOnTop = selectedImages.length === packageItem.images;
            isDisabled = selectedImages.length < packageItem.images; // || hasValidation > 0;
            if (!isDisabled) {
                isDisabled = selectedImages.length % packageItem.images > 0;
            }

            //Add animation if selected & reset
            if (
                selectingPackage.current ===
                packageItem.id + packageItem.name
            ) {
                sendingToCart = "sendtocart";
                selectingPackage.current = null;

                //TODO: With this "solution" the animation doesnt work if you select the same package right after
                //im assuming its because we're removing the class via vanlla js which doesnt trigger
                //a rerender in react so technically after the animation has played & user presses select again,
                //react still thinks it has the 'sendtocart' class but i dont have much idea how to overcome
                setTimeout(() => {
                    let sending = document.getElementsByClassName("sendtocart");
                    if (sending.length > 0) {
                        sending[0].classList.remove("sendtocart");
                    }
                }, 2000);
            } else {
                sendingToCart = "";
            }
            return (
                <article
                    key={packageItem.id}
                    className={`packageItem ${sendingToCart}${
                        isOnTop ? " onTop" : ""
                    }${isDisabled ? " notAvailable" : ""}`}
                >
                    <section className="title">
                        <h4>{packageItem.name}</h4>
                        <h5>
                            {(division % packageItem.images === 0 ||
                                !!packageItem.is_per_image) &&
                                multiplier > 1 &&
                                multiplier + "x"}
                            {/* {division % packageItem.images === 0 && (multiplier + "x")} */}
                            £{packageItem.price_gbp}
                        </h5>
                    </section>
                    <p>
                        {isDisabled &&
                            numberOfItems > 0 &&
                            `You have selected ${
                                selectedImages.length - packageItem.images
                            } extra item(s) for this package`}
                    </p>
                    {displayValidations(packageItem.id)}
                    <article className="selectedImages">
                        {getImages(packageItem, isDisabled, multiplier)}
                        {displayRemainingItems(packageItem)}
                    </article>
                    <article className="description">
                        {multiplier > 1 && (
                            <p>Each package contains the following:</p>
                        )}
                        {packageItem.description ? (
                            <p>{packageItem.description}</p>
                        ) : (
                            <PackageItems packageItem={packageItem} />
                        )}
                        <input
                            type="button"
                            className="select action c-default"
                            value="Select"
                            disabled={isDisabled}
                            onClick={(e) => {
                                let { multiplier } =
                                    calculateMultiplier(packageItem);
                                selectPackageAndAnimate(
                                    e,
                                    packageItem,
                                    multiplier,
                                    packageItem.id + packageItem.name
                                );
                            }}
                        />
                    </article>
                </article>
            );
        });
        return <section className="packages">{packages}</section>;
    };

    return (
        <section>
            <section
                className={`onHidden ${isCurrentStep ? "hide" : "show"}`}
                onClick={handleCurrentStepChange}
            >
                <p>Select your packages</p>
                <input
                    type="button"
                    className="action c-default"
                    value="+"
                    onClick={handleCurrentStepChange}
                />
            </section>
            {selectedImages.length > 0 && (
                <section
                    className={`step packageContainer ${
                        isCurrentStep ? "show" : "hide"
                    }`}
                >
                    <section className="disclaimer">
                        <p>
                            You can only select the colour to be black&white for
                            size 10x8 images.
                        </p>
                    </section>
                    {packageInfo && displayPackages()}
                    {currentStep === 1 && selectedPackages.length > 0 && (
                        <>
                            <input
                                type="button"
                                className="action c-default"
                                value="Back"
                                onClick={prevStep}
                            />
                            <input
                                type="button"
                                className="action c-default"
                                value="Continue"
                                onClick={nextStep}
                            />
                        </>
                    )}
                </section>
            )}
            {/* {isCurrentStep && <p>You haven no images selected.</p>} */}
        </section>
    );
};

export default PackageSelection;
