import { useState, useEffect } from "react";

function getValueFromLS(key, initValue) {
    const savedValue = JSON.parse(sessionStorage.getItem(key));
    if (savedValue) return savedValue;

    if (initValue instanceof Function) return initValue();
    return initValue;
}

export default function useSessionStorage(key, initValue) {
    const [value, setValue] = useState(() => {
        return getValueFromLS(key, initValue);
    });

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
}
