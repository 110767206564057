import styles from "./packageItems.module.scss";

const PackageItems = ({ packageItem }) => {
    let freeItems = [];
    const getPackageItems = (packageItems) => {
        return packageItems.map((packItem) => {
            let isFree = !!packItem.is_free; //or Boolean() or Boolean(Number())
            if (isFree) {
                freeItems.push(packItem)
                return;
            }
            return (
                <li key={Math.random()}>
                    {packItem.quantity}x {packItem.item.size}
                </li>
            )
        })
    }

    return (
        <div className={styles.container}>
            <ul className={styles.listContainer}>{getPackageItems(packageItem.items)}</ul>
            {freeItems.length > 0 &&
                <ul className={styles.listContainer}>
                    <p className={styles.free}>Free</p>
                    {freeItems.map((packItem, itemId) =>
                        <li key={itemId}>
                            {packItem.quantity}x {packItem.item.size}
                        </li>
                    )}
                </ul>
            }
        </div>
    )
}

export default PackageItems;