import Paginator from "../../genericComponents/Paginator";
import {selectDropdown} from "../../../css/admin/photoUpload.module.scss";

const CompanyDropdown = ({
    handleCompanySelection,
    companies,
    selectedCompany,
    setHandler
}) => {
    const dropdownOptions = () => {
        return companies.data
            ? (
                companies.data.map((company) => {
                    return (
                        <option
                            key={company.id}
                            value={company.name}>
                            {company.name}
                        </option>
                    );
                })
            )
            : <option value="No option">No option</option>
    }

    return (
        <section className="companyContainer">
            <div>
                <label htmlFor="companySelection">Select company</label>
            </div>
            <div className={selectDropdown}>
                <select
                    name="companySelection"
                    onChange={handleCompanySelection}
                    value={selectedCompany.name}>
                    <option selected default disabled >Select a company</option>
                    {dropdownOptions()}
                </select>
            </div>
            {companies.per_page < companies.total && <Paginator
                links={companies.links}
                currentPage={companies.current_page}
                last_page={companies.last_page}
                setHandler={setHandler}
            />}
        </section>
    )
}


export default CompanyDropdown;