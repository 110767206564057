import React, { useState, useContext, useEffect } from "react";
import { callApi } from "../services/apiService";
import { LanguageContext } from '../components/contexts/LanguageContext';
import { AuthContext } from '../components/contexts/AuthContext';
import "../css/login.scss";
import { ToastContainer } from 'react-toastify';
import responseHandler from "../helpers/responseHandler";

function Login(props) {
    const languageContext = useContext(LanguageContext);
    const { signIn, getUser, signOut } = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // Check whether user is already logged in or not, redirect if they are
    useEffect(() => {
        const user = getUser();
        const username = user && user.username;
        if (username) props.history.push('/');
    }, [getUser, props.history]);

    const loginDetails = (e) => {
        e.preventDefault();
        let loginBody = {
            username: email,
            password: password
        }

        const additionalHeaders = {
            'Content-Language': languageContext.languageState
        };
        callApi("/login", additionalHeaders, "post", loginBody)
            .then(response => {
                let token = response.data.access_token;
                let user = response.data.user;
                signIn(user, token)
                if (user.role === "super" || user.role === "admin") {
                    props.history.push('/admin/dashboard');
                }
                else if (user.role === "user") {
                    props.history.push('/');
                }
                window.location.reload();
            })
            .catch(({ response }) => {
                responseHandler("error", response.data)
                if(response.status === 401) signOut();
            });
    }
    return (
        <main className="login">
            <ToastContainer />
            <form onSubmit={loginDetails}>
                <h4>Log in to your account</h4>
                <input type="text" name="username" onChange={(e) => setEmail(e.target.value)} placeholder="Username" />
                <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                <input type="submit" value="Login" />
            </form>
        </main>
    );
}

export default Login;
