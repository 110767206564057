import React from "react";
import { ToastContainer } from "react-toastify";
import PhotoSelection from "../shopComponents/PhotoSelection";
import PackageSelection from "../shopComponents/PackageSelection";
import CartPopover from "../cartComponents/CartPopover";
import ShopLogic from "../shopComponents/ShopLogic";
import Checkout from "../checkoutComponents/Checkout";
import Instructions from "../shopComponents/Instructions";
import "../../css/shop.scss";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";

// const usePreviousValue = value => {
//     const ref = useRef();
//     useEffect(() => {
//         ref.current = value;
//     });
//     return ref.current;
// };

//     let props = ShopLogic();
//     const prevCount = usePreviousValue(props);
//     console.table(prevCount)
// console.table(props)

const Shop = () => {
    let {
        selectedImages,
        // changeImageQuantity,
        selectImage,
        removeImage,
        prevStep,
        nextStep,
        currentStep,
        setCurrentStep,
        checkIfAPhotoIsSelected,
        checkIfAlreadySelected,
        packageInfo,
        selectColour,
        selectPackage,
        selectedPackages,
        removeSelectedImage,
        select12by8,
        validationMessages,
        removePackage,
        myPhotos,
        handleInput,
        postOrder,
        deliveryAddress,
        handleApprove,
        createPaypalOrder
    } = ShopLogic();

    return (
        <section className="tab shop">
            <ToastContainer />
            {(myPhotos && myPhotos.length > 0) && <Instructions />}
            {myPhotos && packageInfo ? (
                myPhotos.length === 0
                    ? <p>Your photographer has not yet uploaded any photos taken of you. Please come back in a few days. If it has been a while and you're still seeing this error message, please email davidn@afs.co.uk</p>
                    :
                <>
                    <CartPopover
                        selectedPackages={selectedPackages}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                    />
                    <PhotoSelection
                        selectedImages={selectedImages}
                        // changeImageQuantity={changeImageQuantity}
                        checkIfAlreadySelected={checkIfAlreadySelected}
                        myPhotos={myPhotos}
                        selectImage={selectImage}
                        removeImage={removeImage}
                        nextStep={nextStep}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        checkIfAPhotoIsSelected={checkIfAPhotoIsSelected}
                        removeSelectedImage={removeSelectedImage}
                    />
                    {packageInfo ? (
                        <PackageSelection
                            prevStep={prevStep}
                            nextStep={nextStep}
                            currentStep={currentStep}
                            packageInfo={packageInfo}
                            selectedImages={selectedImages}
                            selectColour={selectColour}
                            selectPackage={selectPackage}
                            selectedPackages={selectedPackages}
                            setCurrentStep={setCurrentStep}
                            select12by8={select12by8}
                            validationMessages={validationMessages}
                        />
                    ) : null}
                    {packageInfo ? (
                        <PayPalScriptProvider
                            deferLoading
                            // options={initialOptions}
                        >
                            <Checkout
                                postOrder={postOrder}
                                myPhotos={myPhotos}
                                selectedPackages={selectedPackages}
                                removePackage={removePackage}
                                currentStep={currentStep}
                                packageInfo={packageInfo.data}
                                handleInput={handleInput}
                                deliveryAddress={deliveryAddress}
                                handleApprove={handleApprove}
                                createPaypalOrder={createPaypalOrder}
                            />
                        </PayPalScriptProvider>
                    ) : null}
                </>
            ) : (
                <div className="loadingGif"></div>
            )}
        </section>
    );
};

export default Shop;
