import {useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CartLogic from "./CartLogic";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons/faShoppingCart";

const CartPopover = ({
    selectedPackages,
    setCurrentStep
}) => {
    const [packages,] = useState(selectedPackages);
    const {
        total_price,
        countPacks
    } = CartLogic();

    //const packages = [...selectedPackages];
    const total = total_price(selectedPackages)
    const displayPacks = countPacks(selectedPackages);

    let displayDropdown = selectedPackages.length > 0;

    function toCheckout() {
        setCurrentStep(2);
    }

    const displayDetails = () => {
        if (displayDropdown) {
            return (
                displayPacks.map((pack) => {
                    return (
                        <li key={pack.id}>
                            <span>{pack.name}</span>
                            <span>£{pack.price} x{pack?.count ?? ""}</span>
                        </li>
                    )
                })
            )
        }
        return <p>You have nothing in your cart</p>
    }

    return (
        <>
            <section className="cart dropdown">
                {total && <p>£{total}</p>}
                <FontAwesomeIcon icon={faShoppingCart} id="cartPopoverButton" />
                <section className="cartDetails dropdownItems">
                    <p className="notice">You'll be able to edit your items in the checkout section.</p>
                    <ul>
                        {displayDetails()}
                    </ul>
                    {displayDropdown && <input type="button" className="action c-default" value="Checkout" onClick={toCheckout} />}
                </section>
            </section>
        </>
    )
}

export default CartPopover;
