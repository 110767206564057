import React from "react";


const constructOrderContent = (products) => {
    return products.map((item) => {

        return (
            <p>{item.quantity}x {item.type.size} {item.type.is_bnw ? '(Black and White)' : ''} {item.is_free ? '(FREE)' : ''}</p>
        )
    })

}

const renderOrders = (orders) => {
    return orders.map((item) => {

        return (
            <div className="row">
                <p>{constructOrderContent(item.products)}</p>
                <p>£{item.total_gbp}</p>
                <p>
                    <span>{item.address.street_line_1}</span>
                    <span>{item.address.street_line_2}</span>
                    <span>{item.address.town}</span>
                    <span>{item.address.county}</span>
                    <span>{item.address.postcode}</span>
                    <span>{item.address.country}</span>
                </p>
                <p>{new Date(item.created_at).toLocaleDateString('en-UK')}</p>
            </div>
        )
    })
}

const noOrdersFound = () => {
    return (
        <p>You have not placed any orders yet.</p>
    )
}

const OrderHistory = (data) => {
    return (
        <section className="tab orderHistory">
            <h3>Order history</h3>

            {(data.orders && data.orders.length > 0) 
            ? <>
            <section className="tableWrapper">
                <div className="row">
                    <p>Products</p>
                    <p>Order Price</p>
                    <p>Sent To</p>
                    <p>Ordered On</p>
                </div>
                {renderOrders(data.orders)}
            </section>
            
            </> 
            : noOrdersFound()}
        </section>
    )
}

export default OrderHistory;