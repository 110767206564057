
import { useState, useEffect, useContext, useCallback } from "react";
import { LanguageContext } from "../../components/contexts/LanguageContext";
import { AuthContext } from "../../components/contexts/AuthContext";
import responseHandler from "../../helpers/responseHandler";
import { callApi } from "../../services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
const Paginator = ({
    links,
    currentPage,
    last_page,
    setHandler
}) => {
    const languageContext = useContext(LanguageContext);
    const { getToken } = useContext(AuthContext);

    const getData = (url) => {
        const additionalHeaders = {
            'Content-Language': languageContext.languageState,
            Authorization: 'Bearer ' + getToken()
        };
        callApi('$'+url, additionalHeaders)
        .then(response => {
            setHandler(response.data.data);
        });
    }

    const getLabel = (item) => {
        if(item.label === "pagination.next")
            return <FontAwesomeIcon icon={faChevronRight} />
        if(item.label === "pagination.previous")
            return <FontAwesomeIcon icon={faChevronLeft} />
        return item.label;
    }

    let pages = links.map((item, id) => {
        if(item.label === 'pagination.next' && currentPage === last_page) return
        if(item.label === 'pagination.previous' && currentPage === 1) return
        if(['pagination.next', 'pagination.previous'].includes(item.label) && last_page <= 1) return;

        return (

            <a href="#" key={id}
            className={item.label === currentPage ? 'active' : ''}
            onClick={() => getData(item.url)}>{
                getLabel(item)
            }</a>
        )
    });
    return (
        <>
            <div className='pagination'>
                {links ? pages : ''}
            </div>
        </>
    )
}

export default Paginator;
