import React, {useState, useEffect, useContext} from "react";
import {ToastContainer} from "react-toastify";
import responseHandler from "../../helpers/responseHandler";
import { DetailsContext } from "../../components/contexts/DetailsContext";
import {pageEdit} from "../../css/admin/pageEdit.module.scss";
import {fileContainer, selectDropdown} from "../../css/admin/photoUpload.module.scss";
import {callApi} from "../../services/apiService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {Button, Card, Collapse, Form } from "react-bootstrap";
import {FileUploader} from "react-drag-drop-files";
import { AuthContext } from '../../components/contexts/AuthContext';


function AlbumEdit(){
    const { albumNames } = useContext(DetailsContext);
    const [description, setDescription] = useState("");
    const [selectedAlbum, setSelectedAlbum] = useState(null);
    const [isOpen, setIsOpen] = useState(true);
    const { signOut } = useContext(AuthContext);

    const handleChange = ({target}) => setDescription(target.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        let body = {
            "id": selectedAlbum,
            description
        };

        callApi('/manage/albums/edit', undefined ,'post', body)
            .then((response) => {
                setSelectedAlbum(null);
                setDescription(null);
                responseHandler('success', response.data);
            })
            .catch(({ response }) => {
                responseHandler('error', response.data)
                if(response.status === 401) signOut();
            });
    }

    const dropdownOptions = () => {
        return albumNames
            ? (
                albumNames.map((album) => {
                    if(album.slug === 'headers') return;
                    return <option key={album.id} value={album.id}>{album.display_name}</option>
                })
            )
            : <option value="No option">No option</option>
    }
    const fileTypes = ["JPG", "PNG", "JPEG"];
    Object.freeze(fileTypes);

    const [droppedFiles, setDroppedFiles] = useState([]);


    return (
        <section className="admin">
            <ToastContainer />
            <Card>
                <Button
                    variant="secondary"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    Album Edit {isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </Button>
                <Collapse in={isOpen}>
                    <Card.Body>
                        <form name='fileUpload' onSubmit={handleSubmit}>
                            <div className={selectDropdown}>
                                <select required onChange={({target}) => setSelectedAlbum(target.value)}>
                                    <option selected default disabled>Select an album</option>
                                    {dropdownOptions()}
                                </select>
                            </div>
                            <FileUploader
                                classes="uploader"
                                multiple
                                children={
                                    <section className={fileContainer}>
                                        <input accept=".jpg,.png,.jpeg" type="file" name="files" />
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>
                                        <span>{droppedFiles.length > 0 ? `${droppedFiles.length} selected file(s)` : "Click to select or drop your photos"}</span>
                                    </section>
                                }
                                handleChange={handleChange}
                                name="files"
                                types={fileTypes}
                                label='Select or drop files here'
                            />
                            <Form.Control
                                name="description"
                                placeholder="Album description"
                                onChange={handleChange}
                                resize="vertical"
                                as="textarea"
                                rows={3}
                            />
                            <input type="submit" value="Update" className="action"/>
                        </form>
                    </Card.Body>
                </Collapse>
            </Card>
        </section>
    );
}

export default AlbumEdit;
