import React, { useLayoutEffect } from "react";
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import Home from '../../pages/Home';
import Photography from '../../pages/Photography';
import About from '../../pages/About';
import Videography from '../../pages/Videography';
import Contact from '../../pages/Contact';
import Privacy from '../../pages/Privacy';
import Terms from '../../pages/Terms';
import Login from '../../pages/Login';
import Profile from '../../pages/Profile';
import ActivateAccount from '../../pages/ActivateAccount';
import ResetPassword from '../../pages/ResetPassword';

function RouteControl() {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Switch>
            <Route exact path='/' component={Home}></Route>
            <Route path='/Photography/:album' component={Photography}></Route>
            <Route path='/About' component={About}></Route>
            <Route path='/Videography/:video' component={Videography}></Route>
            <Route path='/Contact' component={Contact}></Route>
            <Route path='/Privacy-policy' component={Privacy}></Route>
            <Route path='/terms-and-conditions' component={Terms}></Route>
            <Route path='/Logout' component={Login}></Route>
            <Route path='/Login' component={Login}></Route>
            <Route path='/profile' component={Profile}></Route>
            <Route path='/profile/shop' component={Profile}></Route>
            <Route path='/account/reset' component={ResetPassword}></Route>
            <Route path='/account/activate' component={ActivateAccount}></Route>
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );
}

export default RouteControl;
