import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { DetailsContext } from "../components/contexts/DetailsContext";
import Socials from "../components/socials/Socials";
import "../css/home.scss";

function Home() {
    const { albumNames, videoNames } = useContext(DetailsContext);
    // need to move into separate file so it can be imported here and in header
    const scrollToTop = (path) => {
        if (window.location.pathname === path)
            window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const displayServices = () => {
        let albums = albumNames.map((albumObj) => {
            return (
                <NavLink
                    key={albumObj.slug}
                    onClick={() => scrollToTop("/photography")}
                    to={`/photography/${albumObj.slug}`}
                >
                    {albumObj.display_name}
                </NavLink>
            );
        });
        let videos = videoNames.map((videoObj) => {
            return (
                <div className="item">
                    <NavLink
                        key={videoObj.slug}
                        onClick={() => scrollToTop("/videography")}
                        to={`/videography/${videoObj.slug}`}
                    >
                        {videoObj.display_name}
                    </NavLink>
                </div>
            );
        });
        return (
            <article className="servicesContainer">
                <aside className="column">
                    <h4>Photography</h4>
                    {albums}
                </aside>
                <aside className="column">
                    <h4>Videography</h4>
                    {videos}
                </aside>
            </article>
        );
    };
    return (
        <main className="home">
            <section className="landingPage">
                <a href="https://www.youtube.com/watch?v=jVlpO7BSg_g" target="_blank" rel="noreferrer noopener">
                    <video src={`${require("../assets/showreel.mp4").default}#t=3`} muted autoPlay controls loop playsInline></video>
                </a>
            </section>
            {/*<iframe */}
            {/*        width="1110"*/}
            {/*        height="414"*/}
            {/*        src="https://www.youtube.com/embed/jVlpO7BSg_g?autoplay=1&cc_load_policy=1&mute=1&controls=0&modestbranding=1&iv_load_policy=3"*/}
            {/*        title="YouTube video player"*/}
            {/*        frameBorder="0"*/}
            {/*        allow="autoplay"*/}
            {/*        modestbranding="1"*/}
            {/*        controls="false"*/}
            {/*        onready={(e)=>console.log(e)}*/}
            {/*        //allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
            {/*        allowFullScreen></iframe>*/}
            <section className="homeContainer">
                <article>
                    <section className="description">
                        <h3>What We Do</h3>
                        <p>
                            We are a group of young and passionate creators
                            based in the Midlands. The team have multiple
                            experts with different skill sets in content
                            creation and this is what makes us really creative
                            and effective in our services. Our mission is to
                            help individuals and brands to reach their goals,
                            their target audience.
                        </p>
                    </section>

                    <section className="divider"></section>

                    <section className="services">
                        <h3>Services</h3>
                        {displayServices()}
                        <p>
                            If you can't find what you are looking for on our
                            website, please feel free to contact our team. We
                            are more than happy to help with every enquiry.
                        </p>
                    </section>

                    <section className="divider"></section>

                    <section className="reviews">
                        <h3>Client Reviews</h3>
                        <p>The list is increasing as we work.</p>
                        <p>
                            For the time being, check out our social media
                            sites.
                        </p>
                        <Socials />
                    </section>

                    {/*<section className="divider"></section>

                    <section className="partners">
                        <h3>Partners</h3>
                        <p>Some partner logos</p>
                    </section>

                     <section className="availability">
                        <h3>Availability</h3>
                        <article>
                            <FontAwesomeIcon icon={"eye-slash"} />
                            <p>Private photoshoots</p>
                        </article>
                        <article>
                            <FontAwesomeIcon icon={"building"} />
                            <p>Small or medium sized businesses</p>
                        </article>
                        <article>
                            <FontAwesomeIcon icon={"city"} />
                            <p>Large enterprises</p>
                        </article>

                        <p>Our team have a wide range of photography knowledge, whether it’s portrait, advertising or aerial photography, leave it to us!</p>
                        <p>We have acquaintance with video advertising, live action filming, musical video clips and other types of short films.</p>
                    </section> * /}

                    {/*
                            <section className="divider"></section>

                            <section className="photoShowcase">
                                <h3>Portfolio</h3>
                                <Albums />
                            </section>
                            * /}

                    <section className="divider"></section>

                    {/* <section className="industrialServices">
                        <h3>Services</h3>
                        <h5>Industrial Photography Services</h5>
                        <article className="industrialServiceContainer">
                            <section className="serviceCard advertising">
                                <section className="title">
                                    <h5>Advertising Photography</h5>
                                    <img src={team} alt="Team" />
                                </section>
                                <p>We know how important it is to make a strong first impression in the consumer’s mind.</p>
                                <p>Do you want to show your brand’s best side? You will get a professional team and environment with us.</p>
                            </section>
                            {/* <div className="verticalDivider"></div> * /}
                            <section className="serviceCard companyPhotography">
                                <section className="title">
                                    <h5>Company Headshots</h5>
                                    <img src={headshot} alt="Portrait shot" />
                                </section>
                                <p>We recommend this service to individuals and companies who need quality and reliable profile photos for </p>
                                <p>CV, LinkedIn profile or for their company communications - company images.</p>
                            </section>
                        </article>
                        <p>After discussing the details, we offer a personalised package for you and your company.</p>
                        <p>Please call us on weekdays between 9am-5pm on </p>
                        <a href="tel:07513131310">07513 131310</a>
                        <p>or send a message to our email address</p>
                        <a href="mailto:hello@artforcestudios.co.uk?subject=Enquiry">hello@artforcestudios.co.uk</a>
                    </section>

                    <section className="businessEvent">
                        <h3>Business services</h3>
                        <h5>Photography/Videography</h5>
                        <p>How to prepare yourself for the photoshoot?</p>
                        <p>In this section you can find many important information and tips about how to prepare yourself for the big day.</p>
                        <article className="package standard">
                            <h4>Standard</h4>
                            <p>If you have never been to a photoshoot before, this package is the best choice.</p>
                            <p>It includes a 1 hour portrait session with our photographer, David.</p>
                            <p>During the shoot you will see how easy it is to model for a professional photographer.</p>
                            <p>PDF File / Free of charge</p>
                            <p className="price">3 lightly edited photos, now only <span>£60</span> instead of £110</p>
                            <section className="cardContainer">
                                <article className="cardItem headshoot">
                                    <h5>Headshots</h5>
                                    <img src={portrait} alt="Portrait shot" />
                                    <p className="price">5* edited photos, now only <span>£150</span> instead of £250</p>
                                </article>
                                <article className="cardItem portfolio">
                                    <h5>Portfolio session</h5>
                                    <img src={portfolio} alt="Portfolio" />
                                    <p className="price">10* edited photos, now only <span>£300</span> instead of £550</p>
                                </article>
                                <article className="cardItem couples">
                                    <h5>Couple photography</h5>
                                    <img src={couple} alt="Couple" />
                                    <p className="price">7* edited photos, now only <span>£200</span> instead of £350</p>
                                </article>
                                <article className="cardItem family">
                                    <h5>Family portrait</h5>
                                    <img src={family} alt="Family" />
                                    <p className="price">10* edited photos, now only <span>£350</span> instead of £650</p>
                                </article>
                            </section>
                            <p>You also have an option to be photographed in your own home, with a full professional set up.</p>
                            <p>We can provide a make-up artist and hairdresser on request (£50/professional).</p>
                            <article className="finePrint">
                                <p>* = Any extra photo to edit is £10 / photo</p>
                            </article>
                        </article>
                        <article className="package exclusive">
                            <h4>Exclusive</h4>
                            <p>We recommend this package, if you want something unforgettable.</p>
                            <p>A weekend in Budapest, with all-inclusive services.</p>
                            <p>You can discover Budapest with us and walk through the most wonderful and fascinating places.</p>
                            <p>After the trip, you will get more than 30 amazing photos (10x15) in a decorative box and on a memory stick too.</p>
                            <p className="price">£1200 for 1 person | £1700 for a couple | from £2000 for a family</p>
                        </article>
                        <article className="package wedding">
                            <h4>Wedding</h4>
                            <p>For all details, please read our full wedding packages quote</p>
                        </article>
                        <article className="package subscription">
                            <h4>Subscription</h4>
                            <p>If you would like to capture the growing of your child/children, we highly recommend this option.</p>
                            <p>If you work out or do bodybuilding and want to see how your body is changing, it can be also a good choice.</p>
                        </article>
                        <article className="package contactSection">
                            <p>For more details, please don't hesitate to get in touch with us.</p>
                            <p>With any questions or to make a booking, please call us on</p>
                            <a href="tel:07513131310">07513 131310</a>
                            <p>or send us a message to</p>
                            <a href="mailto:hello@artforcestudios.co.uk?subject=Enquiry">hello@artforcestudios.co.uk</a>
                        </article>
                    </section> */}
                </article>
            </section>
        </main>
    );
}

export default Home;
