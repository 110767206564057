import './buttons.scss'
import {NavLink} from "react-router-dom";

export const ContactCTA = () => {
    return (
        <NavLink to="/contact" className="buttonBorder callToAction">
            Get in touch with us
        </NavLink>
    )
}
