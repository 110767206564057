import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { callApi } from "../services/apiService";
import { LanguageContext } from '../components/contexts/LanguageContext';
import responseHandler from "../helpers/responseHandler";
import { header } from '../lang/header'
import "../css/activateAccount.scss";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";

function ActivateAccount(props) {
    const [isValidToken, setIsValidToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [username, setUsername] = useState();
    const [name, setName] = useState({
        firstName: "",
        lastName: ""
    });
    const [isSuccess, setIsSuccess] = useState(null);
    const [token, setToken] = useState(null);
    const passwordRef = useRef(null);
    const passwordConfirmRef = useRef(null);
    const languageContext = useContext(LanguageContext);

    function comparePasswords() {
        return passwordRef.current.value === passwordConfirmRef.current.value
    }

    const checkTokenValidity = useCallback(() => {
        let token = queryString.parse(props.location.search)
        const body = token;
        const additionalHeaders = {
            'Content-Language': languageContext.languageState
        };
        callApi("/account/token", additionalHeaders, "post", body)
            .then(response => {
                setIsValidToken(true);
                setToken(token)
                setUsername(response.data.username)
                setIsLoading(false)
            })
            .catch(({ response }) => {
                setIsValidToken(false);
                setIsLoading(false)
                responseHandler("error", response.data)
            });
    }, [props.location.search])

    const completeRegistration = () => {
        setIsLoading(true)
        let isPasswordSame = comparePasswords();
        if (isPasswordSame) {
            const body = {
                password: passwordRef.current.value,
                password_confirmation: passwordConfirmRef.current.value,
                first_name: name.firstName,
                last_name: name.lastName,
                activation_token: token.token
            }
            const additionalHeaders = {
                'Content-Language': languageContext.languageState
            }
            callApi("/account/activate", additionalHeaders, "post", body)
                .then(response => {
                    setIsSuccess(response.data.success)
                    setIsLoading(false)
                })
                .catch(({ response }) => {
                    // setIsSuccess(err.success)
                    setIsLoading(false);
                    responseHandler("error", response.data)
                });
        }
        else {
            responseHandler("error", { message: "The passwords do not match" });
            setIsLoading(false);
        }
    }

    const showPassword = (type) => {
        if (type === "normal") {
            if (passwordRef.current.type === "text") passwordRef.current.type = "password"
            else if (passwordRef.current.type === "password") passwordRef.current.type = "text"
        };
        if (type === "confirm") {
            if (passwordConfirmRef.current.type === "text") passwordConfirmRef.current.type = "password"
            else if (passwordConfirmRef.current.type === "password") passwordConfirmRef.current.type = "text"
        }
    }

    function handleNameInput(e) {
        let inputName = e.target.name;
        let value = e.target.value;
        setName(
            {
                ...name,
                [inputName]: value
            }
        )
    }

    const validToken = () => (
        <div className="tokenContainer">
            <h4>Complete your account registration</h4>
            <form className="registration" onSubmit={(e) => {
                e.preventDefault();
                completeRegistration();
            }}>
                <label htmlFor="username">Username</label>
                <input type="text" name="username" className="username" disabled="disabled" value={username} />
                <label htmlFor="firstName">First name</label>
                <input type="text" name="firstName" value={name.firstName} onChange={handleNameInput} />
                <label htmlFor="lastName">Last name</label>
                <input type="text" name="lastName" value={name.lastName} onChange={handleNameInput} />
                <label htmlFor="password">Password</label>
                <div>
                    <input
                        name="password"
                        type="password"
                        ref={passwordRef}
                        pattern=".{6,}"
                        required
                        title="6 characters minimum" />
                    <FontAwesomeIcon icon={faEye} onClick={() => showPassword("normal")} />
                </div>
                <label htmlFor="confirmpassword">Confirm Password</label>
                <div>
                    <input
                        name="confirmpassword"
                        type="password"
                        ref={passwordConfirmRef}
                        pattern=".{6,}"
                        required
                        title="6 characters minimum" />
                    <FontAwesomeIcon icon={faEye} onClick={() => showPassword("confirm")} />
                </div>
                <input type="submit" value="Register" />
            </form>
        </div>
    )
    const InvalidToken = () => (
        <div className="tokenContainer">
            <h4>This link is invalid, please contact support.</h4>
        </div>
    )

    useEffect(() => {
        checkTokenValidity();
    }, [checkTokenValidity])

    return (
        <main>
            <ToastContainer />
            {isLoading && <div className="loadingGif"></div>}
            {(isValidToken !== null && isSuccess === null) && (
                isValidToken
                    ? validToken()
                    : <InvalidToken />
            )}
            {isSuccess !== null && (
                <div className="tokenContainer">
                    {isSuccess
                        ? <>
                            <h4>Successfully registered!</h4>
                            Click here to <NavLink to='/login'>{header[languageContext.languageState].login}</NavLink>
                        </>
                        : <h4>Registration was unsuccessful</h4>
                    }
                </div>
            )}
        </main>
    );
}

export default ActivateAccount;
