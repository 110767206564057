import { useState, useEffect, useContext } from "react";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { LanguageContext } from '../../../components/contexts/LanguageContext';
import { AuthContext } from "../../../components/contexts/AuthContext";
import { callApi } from "../../../services/apiService";
import responseHandler from "../../../helpers/responseHandler";

const CompanyLogic = () => {
    const languageContext = useContext(LanguageContext);
    const { getToken } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [companies, setCompanies] = useState(null);
    const [invitations, setInvitations] = useState([]);
    const [pendingInvitation, setPendingInvitation] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState({ id: null, name: "" });
    const [email, setEmail] = useState(null);
    const [companyTab, setCompanyTab] = useState(0);

    const getAllCompanies = () => {
        const additionalHeaders = {
            'Content-Language': languageContext.languageState,
            Authorization: 'Bearer ' + getToken()
        };
        callApi("/manage/companies/all", additionalHeaders)
            .then(response => {
                let fetchedCompanies = response.data.data;
                setCompanies(fetchedCompanies);
                if (fetchedCompanies.length > 0) {
                    setSelectedCompany(fetchedCompanies[0])
                }
                setIsLoading(false)
            })
            .catch(({ response }) => {
                setIsLoading(false)
                responseHandler("error", response.data)
            });
    };

    const getPendingInvitations = () => {
        setIsLoading(true);
        const additionalHeaders = {
            'Content-Language': languageContext.languageState,
            Authorization: 'Bearer ' + getToken()
        };
        callApi("/manage/invite/pending", additionalHeaders)
            .then(response => {
                setInvitations(response.data.data)
                setIsLoading(false)
            })
            .catch(({ response }) => {
                setIsLoading(false)
                responseHandler("error", response.data)
            });
    };
    const confirmedInvModification = (endpoint) => {
        const inviteBody = {
            activation_token: pendingInvitation.activation_token
        }

        const additionalHeaders = {
            'Content-Language': languageContext.languageState,
            Authorization: 'Bearer ' + getToken()
        };

        callApi(`/manage/invite/${endpoint}`, additionalHeaders, "post", inviteBody)
            .then(response => {
                if(endpoint === 'revoke'){
                    setInvitations((inv) => {
                        let data = invitations.data.filter(inv => inv.activation_token !== inviteBody.activation_token);
                        return {
                            ...inv,
                            data
                        }
                    })
                }
                else{
                    setInvitations((inv) => {
                        let {data} = response.data
                        return {
                            ...inv,
                            data
                        }
                    })
                }
                setEmail(null);
                responseHandler("success", response.data)
                setIsLoading(false)
            })
            .catch(({ response }) => {
                setIsLoading(false)
                responseHandler("error", response.data)
            });
    }

    const modifyInvitation = (isResend) => {
        let endpoint = isResend ? "resend" : "revoke";
        confirmAlert({
            title: `Confirm to ${endpoint} invitation`,
            message: `Are you sure you want to ${endpoint} an invitation sent to ${pendingInvitation.sent_to}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => confirmedInvModification(endpoint)
                },
                {
                    label: 'No',
                }
            ]
        });


    }

    const sendInvitation = () => {
        const inviteBody = {
            company_id: selectedCompany.id,
            email: email.value
        }
        const additionalHeaders = {
            'Content-Language': languageContext.languageState,
            Authorization: 'Bearer ' + getToken()
        };

        callApi("/manage/invite/send", additionalHeaders, "post", inviteBody)
            .then(response => {
                responseHandler("success", response.data)
                setIsLoading(false)
            })
            .catch(({ response }) => {
                setIsLoading(false)
                responseHandler("error", response.data)
            });
    }

    function handleCompanySelection(e) {
        let company = companies.data.filter(company => company.name === e.target.value)
        if (company.length > 0) {
            // should always be the one element
            company = company[0];
        }
        setSelectedCompany(prevValue => prevValue = company);
    }

    function handleEmail(e) {
        let emailValue = e.target.value;
        let pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
        let isValid = emailValue.match(pattern)?.length > 0 ? true : false;
        setEmail(prevValue => prevValue = { value: emailValue, isValid });
    }

    function handleEnterPress(e) {
        if ((e.key === 'Enter' || e.type === "click")) {
            if (email.isValid === false) {
                responseHandler("error", { message: "Enter a valid email" })
            }
            else {
                confirmAlert({
                    title: 'Confirm to send invitation',
                    message: `Are you sure you want to send an invitation to ${email.value} at ${selectedCompany.name}`,
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: sendInvitation
                        },
                        {
                            label: 'No',
                        }
                    ]
                });
            }
        }
    }

    function handleScroll(e) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        // if (bottom) {
        //     console.log("here's the bottom")
        // }
    }

    function handleTabSelection(e) {
        let step = e.target.value;
        setCompanyTab(prevValue => prevValue = parseInt(step));
    }

    function handlePendingInvSelection(e) {
        let pendingInv = e.target.value;
        let invitation = invitations.data.filter(inv => inv.sent_to === pendingInv)
        if (invitation.length > 0) {
            // should always be the one element
            invitation = invitation[0];
        }
        setPendingInvitation(prevValue => prevValue = invitation);
    }

    useEffect(() => {
        getPendingInvitations();
    }, []);

    useEffect(() => {

    }, [invitations]);

    useEffect(() => {
        getAllCompanies();
    }, [])

    return {
        getAllCompanies,
        sendInvitation,
        handleEmail,
        handleEnterPress,
        handleCompanySelection,
        handleScroll,
        handleTabSelection,
        handlePendingInvSelection,
        pendingInvitation,
        companyTab,
        companies,
        selectedCompany,
        isLoading,
        invitations,
        modifyInvitation,
        setInvitations,
        setCompanies
    }
}

export default CompanyLogic;
