const Instructions = () => {
    return (
        <ol className="instructions">
            <li>Select your images</li>
            <li>Select your packages</li>
            <li>Select the colour of your images (colour/black and white)</li>
            <li>In some cases, you may be able to select the size too</li>
            <li>Check if you're happy with everything that's in your cart</li>
            <li>Pay for your items</li>
        </ol>
    )
}

export default Instructions;