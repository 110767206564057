import React from "react";
import "./deliveryAddress.scss";
import {PayPalButtons} from "@paypal/react-paypal-js";
// import PropTypes from "prop-types";

const deliveryValidation = () => {};

const DeliveryAddress = ({
    createPaypalOrder,
    handleInput,
    deliveryAddress,
    postOrder,
    handleApprove,
}) => {
    //line1,
    //line2
    //town
    //postcode
    //county
    //country

    function handleSubmit(e) {
        e.preventDefault();
        postOrder();
    }

    async function handleOrderCreation(){
        let id = await postOrder();
        return await createPaypalOrder(id)
            .then(res => {
                return res.data.id;
            })
            .catch(e => {
                console.error(e);
            })
    }

    return (
        <form
            onSubmit={handleSubmit}
            id="deliveryForm"
            className="deliveryAddressForm"
        >
            <section>
                <h6>Delivery address</h6>
                <label htmlFor="street_line_1">
                    Address Line 1 <span className="isMandatory">*</span>
                </label>
                <input
                    className="address"
                    required="required"
                    type="text"
                    name="street_line_1"
                    value={deliveryAddress?.street_line_1 ?? ""}
                    placeholder="Enter your first line of address"
                    onChange={handleInput}
                />
            </section>
            <section>
                <label htmlFor="street_line_2">Address Line 2</label>
                <input
                    className="address"
                    type="text"
                    name="street_line_2"
                    value={deliveryAddress?.street_line_2 ?? ""}
                    placeholder="Enter the second line of your address"
                    onChange={handleInput}
                />
            </section>
            <section>
                <label htmlFor="town">
                    Town <span className="isMandatory">*</span>
                </label>
                <input
                    className="address"
                    required
                    type="text"
                    name="town"
                    value={deliveryAddress?.town ?? ""}
                    placeholder="Enter your town"
                    onChange={handleInput}
                />
            </section>
            <section>
                <label htmlFor="postcode">
                    Postcode <span className="isMandatory">*</span>
                </label>
                <input
                    className="address"
                    required
                    type="text"
                    name="postcode"
                    value={deliveryAddress?.postcode ?? ""}
                    placeholder="Enter your postcode"
                    title="Please enter a valid postcode"
                    pattern="^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$"
                    onChange={handleInput}
                />
            </section>
            <section>
                <label htmlFor="county">County</label>
                <input
                    className="address"
                    type="text"
                    name="county"
                    value={deliveryAddress?.county ?? ""}
                    placeholder="Enter your county"
                    onChange={handleInput}
                />
            </section>
            <section>
                <label htmlFor="country">
                    Country <span className="isMandatory">*</span>
                </label>
                <input
                    className="address"
                    required
                    type="text"
                    name="country"
                    value={deliveryAddress?.country ?? ""}
                    placeholder="Enter your country"
                    onChange={handleInput}
                />
            </section>
            <section>
                <PayPalButtons
                    style={{ layout: "horizontal" }}
                    createOrder={handleOrderCreation}
                    onApprove={handleApprove}
                    buttonText={false}
                />
            </section>
        </form>
    );
};

export default DeliveryAddress;
