import React from "react";

const PasswordReset = ({ resetPassword }) => {
    return (
        <section className="tab resetPassword">
            <h3>Reset your password</h3>
            <p>If you've forgotten your password, click the button below to receive an email to reset your password.</p>
            <input className="action c-default" value="Reset my password" type="button" onClick={() => resetPassword()} />
        </section>
    )
}
export default PasswordReset;