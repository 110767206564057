import React, { useState, useEffect, useRef, useContext } from "react";
import queryString from 'query-string';
import { AuthContext } from "../components/contexts/AuthContext";
import { callApi } from "../services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LanguageContext } from '../components/contexts/LanguageContext';
import { ToastContainer } from 'react-toastify';
import responseHandler from "../helpers/responseHandler";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";

function ResetPassword(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState();
    const [isSuccess, setIsSuccess] = useState(null);
    const passwordRef = useRef(null);
    const passwordConfirmRef = useRef(null);
    const languageContext = useContext(LanguageContext);

    const { getUser } = useContext(AuthContext);

    useEffect(() => {
        const user = getUser();
        const username = user && user.username;
        if (username) setUsername(username);
        else setUsername(false);
    }, [getUser])



    const completeReset = () => {
        setIsLoading(true)
        const isPasswordSame = passwordRef.current.value === passwordConfirmRef.current.value
        let token = queryString.parse(props.location.search)
        if (!token) return null;

        if (isPasswordSame) {
            const body = {
                password: passwordRef.current.value,
                password_confirmation: passwordConfirmRef.current.value,
                password_reset_token: token
            }
            const additionalHeaders = {
                'Content-Language': languageContext.languageState
            }
            callApi("/account/reset", additionalHeaders, "post", body)
                .then(response => {
                    setIsSuccess(response.data.success)
                    setIsLoading(false)
                })
                .catch(({ response }) => {
                    // setIsSuccess(err.success)
                    setIsLoading(false)
                    responseHandler("error", response.data)
                });

        }
        //else console.log("display error message");
    }

    const showPassword = (type) => {
        if (type === "normal") {
            if (passwordRef.current.type === "text") passwordRef.current.type = "password"
            else if (passwordRef.current.type === "password") passwordRef.current.type = "text"
        };
        if (type === "confirm") {
            if (passwordConfirmRef.current.type === "text") passwordConfirmRef.current.type = "password"
            else if (passwordConfirmRef.current.type === "password") passwordConfirmRef.current.type = "text"
        }
    }

    const ValidToken = () => (
        <div>
            <h4>Reset your password</h4>
            <form className="registration" onSubmit={(e) => {
                e.preventDefault();
                completeReset();
            }}>
                <label htmlFor="username">Username</label>
                <input type="text" name="username" className="username" disabled="disabled" value={username} />
                <label htmlFor="password">Password</label>
                <div>
                    <input
                        name="password"
                        type="password"
                        ref={passwordRef}
                        pattern=".{6,}"
                        required
                        title="6 characters minimum" />
                    <FontAwesomeIcon icon={faEye} onClick={() => showPassword("normal")} />
                </div>
                <label htmlFor="confirmpassword">Confirm Password</label>
                <div>
                    <input
                        name="confirmpassword"
                        type="password"
                        ref={passwordConfirmRef}
                        pattern=".{6,}"
                        required
                        title="6 characters minimum" />
                    <FontAwesomeIcon icon={faEye} onClick={() => showPassword("confirm")} />
                </div>
                <input type="submit" value="Reset" />
            </form>
        </div>
    )

    return (
        <main>
            <ToastContainer />
            {isLoading && <div className="loadingGif"></div>}
            <ValidToken />
            {isSuccess !== null && (
                isSuccess
                    ? <h4>Your password was successfully changed!</h4>
                    : <h4>Password reset was snsuccessful</h4>
            )}
        </main>
    );
}

export default ResetPassword;
