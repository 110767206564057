import React, { useState, useEffect, useContext, useCallback } from "react";
import { ToastContainer } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { LanguageContext } from '../components/contexts/LanguageContext';
import { DetailsContext } from '../components/contexts/DetailsContext';
import LoadingScreen from "../components/loading-screen/LoadingScreen"
import { callApi } from "../services/apiService";
import '../css/photography.scss';
import {ContactCTA} from "../components/buttons/CallToAction";
import {AuthContext} from "../components/contexts/AuthContext";
import "../components/buttons/buttons.scss";

function Photography(props) {
    const languageContext = useContext(LanguageContext);
    const detailsContext = useContext(DetailsContext);
    const { getToken } = useContext(AuthContext);
    const [selectedAlbum, setSelectedAlbum] = useState(null);
    const [albumPhotos, setAlbumPhotos] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPageContent, setCurrentPageContent] = useState(null);
    const {pageContents} = detailsContext;
    let footer = document.getElementById('footer');

    const getCurrentPageContent = () => {
        let currentPage = pageContents.filter(page =>
            window.location.pathname.includes(page.page)
        )[0];
        setCurrentPageContent(currentPage);
    }

    const getPhotosForAlbum = useCallback(() => {
        const additionalHeaders = {
            'Content-Language': languageContext.languageState
        };
        callApi(`/albums/paginate/${selectedAlbum}`, additionalHeaders)
            .then(response => {
                props.history.push(`/photography/${selectedAlbum}`);
                setAlbumPhotos(response.data);
                setIsLoading(false)
            })
            .catch(({ _response }) => {
                setIsLoading(false)
                setSelectedAlbum()
                setAlbumPhotos();
                props.history.push('/');
                //responseHandler("error", response.data)
            });
    }, [languageContext.languageState, selectedAlbum, props.history]);

    useEffect(() => {
        if (!isLoading) footer.style.removeProperty("display")
        else {
            if (footer) footer.style.display = 'none';
        }
    }, [isLoading])

    const getSlugFromUrl = () => {
        return window.location.pathname.split('/photography')[1]?.substring(1);
    }

    // Get params from url on load
    useEffect(() => {
        const albumFromUrl = getSlugFromUrl();
        if (albumFromUrl && albumFromUrl !== selectedAlbum) {
            setIsLoading(true);
            setSelectedAlbum(albumFromUrl)
        }
        getCurrentPageContent()
    }, [window.location.pathname, selectedAlbum])

    useEffect(() => {
        // Only run API call if the value has been set
        selectedAlbum && getPhotosForAlbum();
    }, [selectedAlbum, getPhotosForAlbum]);

    function loadMore(){
        const additionalHeaders = {
            'Content-Language': languageContext.languageState,
            Authorization: 'Bearer ' + getToken()
        };
        callApi(`$${albumPhotos.next_page_url}`, additionalHeaders)
            .then(response => {
                setAlbumPhotos(prevState => ({
                    ...response.data,
                    data: [...prevState.data, ...response.data.data]
                }));
            });
    }

    const Photos = () => {
        let photosWithPath = [[], [], []]
        albumPhotos && albumPhotos.data.forEach((photo, id) => {
            if(id === 1) return;
            let colNum = id % 3
            photosWithPath[colNum].push(photo);
        })

        let columns = photosWithPath.map((col, id) => {
            return (
                <div className={`column ${id}`} key={id} >
                    {
                        col.map(
                            (photo, id) => {
                                return <img className={`photo ${photo.fullpath}`} key={id} src={`data:image/png;base64,${photo.base64}`} alt='' />
                            })
                    }

                </div>
            )
        })

        return (
            <>
                <section className="row">{columns}</section>
                {albumPhotos.next_page_url &&
                    <input type="button" value="See more" className="buttonBorder" onClick={loadMore}/>
                }
            </>
        )
    }

    const parseDescription = (desc) => {
        return (
            <p style={{'white-space': 'pre-wrap'}}>{desc}</p>
        )
    }

    return (
        <>
            <LoadingScreen isLoading={isLoading} />
            <main className="portfolio" style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                <ToastContainer />
                {
                    <>
                        {albumPhotos &&
                            <section
                                className="landingPage"
                                style={{
                                    background: albumPhotos?.thumbnail ? `url(data:image/png;base64,${albumPhotos.thumbnail.base64})` : 'black',
                                }}>
                                <article className="subNavigation">
                                    <h3>{currentPageContent?.title ?? selectedAlbum}</h3>
                                    <section>
                                        <NavLink to="/">Home</NavLink>
                                        /
                                        <p className="currentAlbum">{currentPageContent?.subtitle ?? selectedAlbum}</p>
                                    </section>
                                </article>
                            </section>
                        }
                        <section className="albumHeader">
                            {((albumPhotos?.data?.length > 0 && albumPhotos?.data[1]?.base64) ?? albumPhotos?.base64) &&
                                <aside>
                                    <img src={`data:image/png;base64,${albumPhotos?.data[1]?.base64 ?? albumPhotos.base64}`} alt='Wedding' />
                                </aside>
                            }
                            <aside>
                                {albumPhotos && parseDescription(albumPhotos.description)}
                                {/*<p>Starting from £250 (prices can vary between categories)</p>*/}
                                {/*<p className="description">{selectedAlbum && fetchCorrectDescription()}</p>*/}
                                <ContactCTA />
                            </aside>
                        </section>

                        {currentPageContent?.contents.length > 0 &&
                            <section className="content">
                                {currentPageContent.contents.map(section => {
                                    return (
                                        <section>
                                            <h4>{section.section_title}</h4>
                                            <p>{section.section_content}</p>
                                        </section>
                                    )
                                })}
                            </section>
                        }
                        {!isLoading && <Photos />}
                    </>
                }
            </main>
        </>
    );
}

export default Photography;
