export const languageReducer = (state, action) => {
    switch(action.type){
        case "en":
            return "en";
        case "hu":
            return "hu";
        case "de":
            return "de";
        default:
            return "hu";
    }
}