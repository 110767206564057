import React, { useState, useEffect, useContext, useCallback } from "react";
import noir from "../assets/noirScreenshot.png";
import { NavLink } from 'react-router-dom';
import "../css/videography.scss";
import LoadingScreen from "../components/loading-screen/LoadingScreen";
import { LanguageContext } from '../components/contexts/LanguageContext';
import { callApi } from "../services/apiService";
import { ToastContainer } from 'react-toastify';
import responseHandler from "../helpers/responseHandler";
import {ContactCTA} from "../components/buttons/CallToAction";
import {DetailsContext} from "../components/contexts/DetailsContext";
import {backgroundSrc} from "../helpers/helperFunctions";

function Videography(props) {
    const languageContext = useContext(LanguageContext);
    const [isLoading, setIsLoading] = useState(() => setTimeout(() => setIsLoading(false), 1000))
    const [selectedVideoAlbum, setSelectedVideoAlbum] = useState(null);
    const [albumVideos, setAlbumVideos] = useState(null);

    const detailsContext = useContext(DetailsContext);
    const {pageContents} = detailsContext;
    const [currentPageContent, setCurrentPageContent] = useState(null);

    useEffect(() => {
        let currentPage = pageContents.filter(page =>
            window.location.pathname.includes(page.page)
        )[0];
        setCurrentPageContent(currentPage);
    }, [])

    // Get params from url on load
    useEffect(() => {
        let albumFromUrl = window.location.pathname.split('/videography')[1];

        albumFromUrl = albumFromUrl.substring(1)
        if (albumFromUrl && albumFromUrl !== selectedVideoAlbum) {
            setIsLoading(true);
            setSelectedVideoAlbum(albumFromUrl);
        }
    }, [window.location.pathname, selectedVideoAlbum])

    const getVideosForAlbum = useCallback(() => {
        const additionalHeaders = {
            'Content-Language': languageContext.languageState
        };
        callApi(`/videoalbums/paginate/${selectedVideoAlbum}`, additionalHeaders)
            .then(response => {
                props.history.push(`/videography/${selectedVideoAlbum}`);
                setAlbumVideos(response.data.data);
                setIsLoading(false)
            })
            .catch((response) => {
                setIsLoading(false)
                setSelectedVideoAlbum();
                setAlbumVideos();
                props.history.push('/');
                responseHandler("error", response.data)
            });
    }, [languageContext.languageState, selectedVideoAlbum, props.history]);

    useEffect(() => {
        // Only run API call if the value has been set
        selectedVideoAlbum && getVideosForAlbum();
    }, [selectedVideoAlbum, getVideosForAlbum]);

    const Videos = () => {
        let videosWithPath = [[], [], []]
        albumVideos && albumVideos.forEach((video, id) => {
            let colNum = id % 3;
            videosWithPath[colNum].push(video);
        });

        let columns = videosWithPath.map((col, id) => {
            return (
                <div className={`column ${id}`} key={id} >
                    <video width={400} loop={true} autoPlay controls id="clip" muted>
                        {
                            col.map(
                                (video, id) => {
                                    return <source className='video' src={video.link} key={id} type="video/mp4" />
                                })
                        }
                    </video>
                </div>
            )
        })

        return <section className="row">{columns}</section>
    }

    const fetchCorrectDescription = () => {
        let category = selectedVideoAlbum.toLowerCase();
        switch(true){
            case category === 'music':
                return "We are offering professional Music Video production from low to big budget. No matter if you are a solo artist or a band we got you covered from start to finish. We can help develop the story and the look you are aiming for. Professional executing from Pre Production all the way to Production and Post Production. Music Video trailers, behind the scenes content or a photography session on set? Say no more. No music videos are the same, we offer personalised Videos to the highest standards. For quotes feel free to send us a message.";
            case category === 'commercial':
                return "We offer Commercial filmmaking for businesses to represent they company, brand or product with an appealing personalised video. We all know how important it is to have professional content of your business.It can help to establish your brand identity and to build trust or attract customers. We are here at Art Force Studios ready to help you through all aspects of the production. Commercials, Product Videos, Interviews, Short Documentaries, you dream it, we make it."
            case category === 'real_estate':
                return "Art Force Studios offers professional Real Estate Video services from small houses to luxury properties. Our experienced team has great attention to detail when it comes to architecture home design filming or photography. We also offer 360 home tours as well as aerial shots. For more details please contact our team."
            default: break;
        }
    }

    return (
        <>
            <LoadingScreen isLoading={isLoading} />
            <main className="videography">
                <ToastContainer />
                <section
                    className="landingPage"
                    style={{ backgroundImage: backgroundSrc(currentPageContent?.header?.base64, noir) }}>
                    <article className="subNavigation">
                        <h3>Videography</h3>
                        <section>
                            <NavLink to="/">Home</NavLink>
                            /
                            <p className="currentAlbum">Videography {selectedVideoAlbum?.replace(/_/g, ' ')}</p>
                        </section>
                    </article>
                </section>
                <section className="albumHeader">

                    <p>Starting from £999 (prices can vary between categories)</p>
                    {selectedVideoAlbum && <p className="description">{fetchCorrectDescription()}</p>}
                    <ContactCTA />
                </section>
                {currentPageContent?.contents.length > 0 &&
                    <section className="content">
                        {currentPageContent.contents.map(section => {
                            return (
                                <section>
                                    <h4>{section.section_title}</h4>
                                    <p>{section.section_content}</p>
                                </section>
                            )
                        })}
                    </section>
                }
                {/*{!isLoading && <Videos />}*/}
            </main >
        </>
    );
}

export default Videography;
