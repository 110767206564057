import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {NavLink, useLocation} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import BackToTop from "../buttons/BackToTop";
import { AuthContext } from "../contexts/AuthContext";
import { LanguageContext } from "../contexts/LanguageContext";
import { DetailsContext } from "../contexts/DetailsContext";
import { header } from "../../lang/header";
import { sideNavElements } from "../navigation/adminNavigation";
import logo from "../../assets/logo.png";
import "./header.scss";
import clsx from "clsx";

let html = document.getElementsByTagName("html")[0];
let root = document.getElementsByTagName("body")[0];
let nav = document.getElementsByClassName("right")[0];
const scrollbarWidth = window.innerWidth - document.body.clientWidth;
function Header() {
    const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
    const [scrolledMore, setScrolledMore] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isScrollable, setIsScrollable] = useState(true);
    const [title, setTitle] = useState("");
    const languageContext = useContext(LanguageContext);
    const { albumNames, videoNames } = useContext(DetailsContext);
    const { isAdmin, getUser, isUserLoggedIn, signOut } =
        useContext(AuthContext);
    const {pathname} = useLocation();
    useEffect(() => {
        if(isAdmin()){
            // Hard coding this..
            let splitPath = pathname.split('/')[2];
            if(splitPath.includes("-")){
                let splitTitle = splitPath.replace("-", " ");
                setTitle(splitTitle);
            }
            else setTitle(splitPath);
        }
    }, [pathname])

    useEffect(() => {
        window.addEventListener("scroll", onScrollCheck);
        return () => window.removeEventListener("scroll", onScrollCheck);
    });

    const onScrollCheck = () => {
        if (document.documentElement.scrollTop < 1) {
            setScrolledMore(false);
        }

        if (document.documentElement.scrollTop > 100) {
            setScrolledMore(true);
        }
    };

    useEffect(() => {
        if (isScrollable){
            root.style.removeProperty("overflow");
            html.style.removeProperty("overflow");
        }
        else {
            setScrolledMore(false);
            html.style.overflow = "hidden";
            root.style.overflow = "hidden";
            if (nav) nav.style.paddingRight = scrollbarWidth;
        }
    }, [isScrollable]);

    useEffect(() => {
        const user = getUser();
        const username = user && user.username;
        if (username) setUserIsLoggedIn(username);
        else setUserIsLoggedIn(false);
    }, [getUser, isUserLoggedIn]);

    const navBar = sideNavElements.map((el, index) => {
        let path =
            el.text.toLowerCase() !== "logout"
                ? "/admin" + el.path
                : el.path;
        return (
            <li key={index}>
                <NavLink
                    to={path}
                    onClick={() => {
                        setIsOpen(false);
                        if (el.text.toLowerCase() === "logout") {
                            setUserIsLoggedIn(false);
                            window.location.reload();
                            signOut();
                        }
                    }}
                >
                    {el.icon}
                    <p>{el.text}</p>
                </NavLink>
            </li>
        );
    });
    const SideNav = () => {
        return (
            <nav className={clsx(isOpen && "open", "sidebar-menu", "adminNav")}>
                <FontAwesomeIcon icon={faTimes} onClick={() => setIsOpen(false)} className='adminNavButton'/>
                <ul className="navigationBar vertical menu">
                    <img src={logo} alt="Art Force Studio" />
                    {navBar}
                </ul>
            </nav>
        );
    };

    /*
    const AdminHeader = () => (
        <>
            <section className='left'>
                <NavLink to='/'>{header[languageContext.languageState].dashboard}</NavLink>
                <NavLink to='/settings'>{header[languageContext.languageState].settings}</NavLink>
                <NavLink to='/photos'>{header[languageContext.languageState].photos}</NavLink>
                <NavLink to='/videos'>{header[languageContext.languageState].videos}</NavLink>
                <NavLink to='/customers'>{header[languageContext.languageState].customers}</NavLink>
                </section>
                <section className='right'>
                {
                userIsLoggedIn
                    ?
                    <>
                        <NavLink to='/' onClick={() => { setUserIsLoggedIn(false); signOut(); }}>Logout</NavLink>
                        <p>You're logged in as {userIsLoggedIn}</p>
                    </>
                    : <NavLink to='/login'>{header[languageContext.languageState].login}</NavLink>
                }
                </section>
        </>
    )

    */
    const scrollToTop = () => {
        if (document.documentElement.scrollTop > 0)
            window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const UserHeader = () => (
        <header
            className={
                (isAdmin() ? "dashboard" : "") +
                (scrolledMore ? " scrolled" : "")
            }
        >
            <Navbar
                expand="md"
                variant="dark"
                id="navBar"
                onToggle={() => setIsScrollable(!isScrollable)}
                // style={{ marginRight: !isScrollable ? scrollbarWidth : 0 }}
            >
                <section className="left">
                    <NavLink to="/">
                        <img src={logo} alt="Art Force Studio" />
                    </NavLink>
                </section>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    variant="dark"
                />
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    className={!isScrollable && "show"}
                >
                    <section className="right">
                        <NavLink onClick={() => scrollToTop("/")} to="/">
                            {header[languageContext.languageState].home}
                        </NavLink>
                        <div className="dropdown">
                            <a className="nonClickable">
                                {
                                    header[languageContext.languageState]
                                        .photography
                                }
                                <FontAwesomeIcon icon={faChevronDown} />
                            </a>
                            <div
                                className={
                                    "dropdownItems" +
                                    (scrolledMore ? " scrolled" : "")
                                }
                            >
                                {albumNames.map((albumObj) => {
                                    return (
                                        <NavLink
                                            key={albumObj.slug}
                                            onClick={() => {
                                                setIsScrollable(true);
                                                scrollToTop("/photography");
                                            }}
                                            to={`/photography/${albumObj.slug}`}
                                        >
                                            {albumObj.display_name}
                                        </NavLink>
                                    );
                                })}
                            </div>
                        </div>
                        {/* <NavLink onClick={() => scrollToTop("/videography")} to='/videography'>{header[languageContext.languageState].videography}</NavLink> */}
                        <div className="dropdown">
                            <a className="nonClickable">
                                {
                                    header[languageContext.languageState]
                                        .videography
                                }
                                <FontAwesomeIcon icon={faChevronDown} />
                            </a>
                            <div
                                className={
                                    "dropdownItems" +
                                    (scrolledMore ? " scrolled" : "")
                                }
                            >
                                {videoNames.map((videoObj) => {
                                    return (
                                        <NavLink
                                            key={videoObj.slug}
                                            onClick={() => {
                                                setIsScrollable(true);
                                                scrollToTop("/videography");
                                            }}
                                            to={`/videography/${videoObj.slug}`}
                                        >
                                            {videoObj.display_name}
                                        </NavLink>
                                    );
                                })}
                            </div>
                        </div>
                        <NavLink
                            onClick={() => scrollToTop("/about")}
                            to="/about"
                        >
                            {header[languageContext.languageState].about}
                        </NavLink>
                        <NavLink
                            onClick={() => scrollToTop("/contact")}
                            to="/contact"
                        >
                            {header[languageContext.languageState].contact}
                        </NavLink>
                        {userIsLoggedIn ? (
                            <>
                                <NavLink
                                    to={{
                                        pathname: "/profile",
                                        state: {
                                            match: "profile",
                                        },
                                    }}
                                    className="username"
                                >
                                    My Profile
                                </NavLink>
                                <NavLink
                                    to={{
                                        pathname: "/profile/shop",
                                        state: {
                                            match: "shop",
                                        },
                                    }}
                                    className="important"
                                >
                                    Shop
                                </NavLink>
                                <span>|</span>
                                <NavLink
                                    to="/"
                                    onClick={() => {
                                        setUserIsLoggedIn(false);
                                        signOut();
                                    }}
                                >
                                    Logout
                                </NavLink>
                            </>
                        ) : (
                            <NavLink to="/login">
                                {header[languageContext.languageState].login}
                            </NavLink>
                        )}
                    </section>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );

    return (
        <>
            {isAdmin() ?
                <>
                    <section className="adminNavContainer">
                        <FontAwesomeIcon className='adminNavButton' icon={faBars} onClick={() => setIsOpen(true)} />
                        <h4>{title}</h4>
                    </section>
                    <SideNav />
                </>
                : <UserHeader />}
            <BackToTop scrollToTop={scrollToTop} scrolledMore={scrolledMore} />
        </>
    );
}

export default Header;
