import React, {useState, useContext, useEffect} from "react";
import "../css/about.scss";
import LoadingScreen from "../components/loading-screen/LoadingScreen"
import about from "../assets/about.jpg";
import { NavLink } from 'react-router-dom';
import { DetailsContext } from '../components/contexts/DetailsContext';
import {backgroundSrc} from "../helpers/helperFunctions";

function About() {
    const [isLoading, setIsLoading] = useState(() => setTimeout(() => setIsLoading(false), 1000))
    const [currentPageContent, setCurrentPageContent] = useState(null);
    const [headerImage, setHeaderImage] = useState("");
    const detailsContext = useContext(DetailsContext);
    const {
        pageContents,
        albumNames
    } = detailsContext;

    useEffect(() => {
        let pageContent = pageContents.filter(page => page.page === 'about')[0];
        setCurrentPageContent(pageContent);
    }, [])
    
    return (
        <>
            <LoadingScreen isLoading={isLoading} />
            <main className="about">
                <section
                    className="landingPage"
                    style={{ backgroundImage: backgroundSrc(currentPageContent?.header?.base64, about) }}>
                    <article className="subNavigation">
                        <h3>{currentPageContent?.title ?? 'About Us'}</h3>
                        <section>
                            <NavLink to="/">Home</NavLink>
                            /
                            <p>{currentPageContent?.subtitle ?? 'About Us'}</p>
                        </section>
                    </article>
                </section>
                <section className="description">
                    {
                        currentPageContent?.contents.length > 0 &&
                            currentPageContent.contents.map(section => {
                                return (
                                    <>
                                        <h4>{section.section_title}</h4>
                                        <p>{section.section_content}</p>
                                    </>
                                )
                            })
                    }
                </section>
            </main >
        </>
    );
}

export default About;
