import React from "react";

const ShoppingCart = () => {
    return (
        <section className="tab cart">
            <h3>Your shopping cart</h3>
        </section>
    )
}

export default ShoppingCart;