import Photo from "../shopComponents/Photo";
import PackageItems from "../shopComponents/PackageItems";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import DeliveryAddress from "../shopComponents/DeliveryAddress";
const Checkout = ({
    deliveryAddress,
    postOrder,
    myPhotos,
    selectedPackages,
    removePackage,
    currentStep,
    packageInfo,
    handleInput,
    handleApprove,
    createPaypalOrder
}) => {
    let isCurrentStep = currentStep === 2;
    function confirmRemove(pack) {
        removePackage(pack);
    }

    const initialOptions = {
        "client-id": "AVWyUMIhp_0NCX4mT5VXYZiB8O3dBpjBEw6LesIz7ZIve4pAeiszjN2BUYMibToVhJGKthA_oZ0xuCS5",
        currency: "GBP",
    };

    Object.freeze(initialOptions);

    const displayCart = () => {
        if (selectedPackages.length > 0)
            return selectedPackages.map((pack, idx) => {
                let currentPackage = packageInfo.filter(
                    (currPack) => currPack.id === pack.id
                )[0];
                if (pack.contents) {
                    let images = pack.contents.map((content) => {
                        let image = myPhotos.filter(
                            (image) => image.id === content.photo_id
                        )[0];
                        let isBig = pack.largeImages.filter(
                            (imageObj) => imageObj.photo_id === image.id
                        )[0];
                        let imageSource = image.base64;
                        return (
                            <section
                                className="packageImages"
                                key={idx + content.photo_id}
                            >
                                <Photo
                                    base64={imageSource}
                                    alt="Selected"
                                    className={!content.isColour && "bnw"}
                                />
                                <p>{content.isColour ? "Coloured" : "B&W"}</p>
                                {isBig && <p>12x8</p>}
                            </section>
                        );
                    });
                    return (
                        <section className="checkoutItem" key={idx}>
                            <article className="checkoutItemHeader">
                                <h5>{pack.name}</h5>
                                <h5 className="price">
                                    £{pack.price}
                                    {pack.multiplier > 1 &&
                                        `x${pack.multiplier}`}
                                </h5>
                                <input
                                    className="action c-default"
                                    type="button"
                                    value="Remove"
                                    onClick={() => confirmRemove(pack)}
                                />
                            </article>
                            <section className="items">
                                <p className="bold">Items</p>
                                <PackageItems packageItem={currentPackage} />
                            </section>
                            <section className="packageDisplay">
                                <p className="bold">Image(s)</p>
                                <article>{images}</article>
                            </section>
                        </section>
                    );
                }
            });
        return <p>You have nothing in your cart</p>;
    };

    return (
        <>
            <section
                className={`checkout step ${isCurrentStep ? "show" : "hide"}`}
            >
                <h5>
                    Make sure you're happy with everything that's in your basket
                    before continuing to pay for them.
                </h5>
                <h6>
                    Note: All sizes will come coloured by default and only 10x8
                    may be black & white
                </h6>

                <PayPalScriptProvider options={initialOptions}>
                    <section className="sidesContainer">
                        <section className="left">{displayCart()}</section>
                        <section className="right">
                            <DeliveryAddress
                                handleInput={handleInput}
                                deliveryAddress={deliveryAddress}
                                postOrder={postOrder}
                                handleApprove={handleApprove}
                                createPaypalOrder={createPaypalOrder}
                            />
                        </section>
                    </section>
                </PayPalScriptProvider>
            </section>
        </>
    );
};

export default Checkout;
