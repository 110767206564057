import React, {useRef, useContext, useState, useEffect} from "react";
import { NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Socials from "../components/socials/Socials";
import LoadingScreen from "../components/loading-screen/LoadingScreen"
import { LanguageContext } from '../components/contexts/LanguageContext';
import { callApi } from "../services/apiService";
import responseHandler from "../helpers/responseHandler";
import contact from "../assets/contact.jpg";
import "../css/contact.scss";
import {DetailsContext} from "../components/contexts/DetailsContext";
import {backgroundSrc} from "../helpers/helperFunctions";

function Contact() {
    const detailsContext = useContext(DetailsContext);
    const {pageContents} = detailsContext;
    const [currentPageContent, setCurrentPageContent] = useState(null);

    const [isLoading, setIsLoading] = useState(() => setTimeout(() => setIsLoading(false), 1000))
    const languageContext = useContext(LanguageContext);
    const contactFormRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();

    useEffect(() => {
        let pageContent = pageContents.filter(page => page.page === 'contact')[0];
        setCurrentPageContent(pageContent);
    }, [])

    const increaseTextArea = (e) => {
        e.target.style.height = "";
        e.target.style.height = e.target.scrollHeight + "px";
    }

    const sendEnquiry = () => {
        let contactBody = {
            first_name: firstNameRef.current.value,
            last_name: lastNameRef.current.value,
            email: emailRef.current.value,
            message: messageRef.current.value,
        }
        const additionalHeaders = {
            'Content-Language': languageContext.languageState
        };
        callApi("/contact", additionalHeaders, "post", contactBody)
            .then(response => {
                responseHandler("success", response.data)
                contactFormRef.current.reset()
            })
            .catch(({ response }) => {
                responseHandler("error", response.data)
            });
    }

    return (
        <>
            <LoadingScreen isLoading={isLoading} />
            <main className="contact">
                <ToastContainer />
                <section
                    className="landingPage"
                    style={{ backgroundImage: backgroundSrc(currentPageContent?.header?.base64, contact) }}>
                    <article className="subNavigation">
                        <h3>{currentPageContent?.title ?? 'Contact Us'}</h3>
                        <section>
                            <NavLink to="/">Home</NavLink>
                            /
                            <p>{currentPageContent?.subtitle ?? 'Contact Us'}</p>
                        </section>
                    </article>
                </section>
                <section className="contactDetails">
                    <h4>Get in touch with us!</h4>
                    <form ref={contactFormRef} onSubmit={(e) => {
                        e.preventDefault();
                        sendEnquiry();
                    }}>
                        <section className="name">
                            <aside>
                                <label htmlFor="first_name">Firstname</label>
                                <input name="first_name" type="text" required ref={firstNameRef} />
                            </aside>
                            <aside>
                                <label htmlFor="last_name">Lastname</label>
                                <input name="last_name" type="text" required ref={lastNameRef} />
                            </aside>
                        </section>
                        <section>
                            <label htmlFor="email">Email</label>
                            <input name="email" type="email" required ref={emailRef} />
                        </section>
                        <section>
                            <label htmlFor="message">Message</label>
                            <textarea name="message" onInput={e => increaseTextArea(e)} required ref={messageRef}></textarea>
                        </section>
                        <section>
                            <input value="Send" type="submit" required />
                        </section>
                    </form>
                    <div className="horiztontalSeparator"></div>
                    <Socials isText/>
                </section>
                {currentPageContent?.contents.length > 0 &&
                    <section className="content">
                        {currentPageContent.contents.map(section => {
                            return (
                                <section>
                                    <h4>{section.section_title}</h4>
                                    <p>{section.section_content}</p>
                                </section>
                            )
                        })}
                    </section>
                }
            </main>
        </>
    );
}

export default Contact;
