import axios from 'axios';
import { constants } from '../helpers/constants';

export function callApi(apiUrl, headers, type, body) {
    const headerDefault = {
        Accept: "application/json",
        ...headers
    }

    const url = apiUrl.includes("$") ? apiUrl.replace('$', '').replace("https://", "http://") : constants.apiUri + apiUrl;
    switch (type) {
        case "post":
            return axios.post(url, body, { headers: headerDefault });
        case "put":
            return axios.put(url, body, { headers: headerDefault });
        default:
            return axios.get(url, { headers: headerDefault });
    }
}
