import React from "react";

const MyProfile = (user) => {
    let { profile } = user;
    let { username, first_name, last_name, email } = profile;

    return (
        <section className="tab myProfile">
            <section className="personal">
                <h4>Personal Information</h4>
                <div className="field-group">
                    <p>Username:</p>
                    <p>{username ?? "-"}</p>
                </div>
                <div className="field-group">
                    <p>First Name:</p>
                    <p>{first_name ?? "-"}</p>
                </div>
                <div className="field-group">
                    <p>Last Name:</p>
                    <p>{last_name ?? "-"}</p>
                </div>
                <div className="field-group">
                    <p>Email Address:</p>
                    <p>{email ?? "-"}</p>
                </div>
            </section>
            <section className="company">
                <h4>Company Information</h4>
                <div className="field-group">
                    <p>Company Name:</p>
                    <p>
                        {profile && profile.company && profile.company.name
                            ? profile.company.name
                            : ""}
                    </p>
                </div>
            </section>
        </section>
    );
};

export default MyProfile;
