export const loginReducer = (state, action) => {
    switch(action.type){
        case "RETRIEVE_TOKEN":
            return {
                ...state,
                accessToken: action.token,
                username: action.username
            }
        case "LOGIN":
            return {
                ...state,
                accessToken: action.token,
                username: action.id
            };
        case "LOGOUT":
            return {
                ...state,
                accessToken: null,
                username: null
            };
        case "REGISTER":
            return {
                ...state,
                accessToken: action.token,
                username: action.id
            };

        default:
            return {}
    }
}