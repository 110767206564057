import React, {useState, useEffect, useContext, useRef} from "react";
import {ToastContainer} from "react-toastify";
import responseHandler from "../../helpers/responseHandler";
import { DetailsContext } from "../../components/contexts/DetailsContext";
import {pageEditContentContainer, inputContainer} from "../../css/admin/pageEdit.module.scss";
import {fileContainer, selectDropdown} from "../../css/admin/photoUpload.module.scss";
import {callApi} from "../../services/apiService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {Button, Card, Collapse} from "react-bootstrap";
import {FileUploader} from "react-drag-drop-files";
import { AuthContext } from '../../components/contexts/AuthContext';

function PageEdit(){
    const { pageContents } = useContext(DetailsContext);
    const [pageSection, setPageSection] = useState({});
    const [selectedSection, setSelectedSection] = useState(null);
    const [isNewContent, setIsNewContent] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);
    const [updatedPage, setUpdatedPage] = useState({});
    const pages = pageContents;
    const selectRef = useRef();
    const [isOpen, setIsOpen] = useState(true);
    const [droppedFiles, setDroppedFiles] = useState([]);

    const { signOut,getToken } = useContext(AuthContext);
    const fileTypes = ["JPG", "PNG", "JPEG"];
    Object.freeze(fileTypes);

    const hasPageBeenUpdated = () => {
        // Quick but limited comparison - order must be the same as updatedPage
        let simplifiedSelectedPage = {
            title: selectedPage?.title,
            subtitle: selectedPage?.subtitle,
            id: selectedPage?.id,
        }
        return JSON.stringify(updatedPage) === JSON.stringify(simplifiedSelectedPage)
    }

    const handleChange = (e) => {
        let inputName = e.target.name;
        let value = e.target.value;
        setUpdatedPage({
            ...updatedPage,
            [inputName]: value,
        });
    };
    const handleSectionChange = (e) => {
        let inputName = e.target.name;
        let value = e.target.value;
        setPageSection({
            ...pageSection,
            [inputName]: value,
        });
    };
    const handlePageSectionChange = (e) => {
        let inputName = e.target.name;
        let value = e.target.value;
        setSelectedSection({
            ...selectedSection,
            [inputName]: value,
        });
    };

    const handleFileChange = (file) => setDroppedFiles([file]);

    const handleContentEditOrCreation = async () => {
        if(!selectedSection && !isNewContent) return;
        let body, url;
        if(isNewContent){
            url = '/content/create'
            body = {
                page: selectedPage.page,
                section_title: pageSection?.section_title,
                section_content: pageSection?.section_content
            }
        }
        else {
            url = '/content/edit';
            body = selectedSection;
        }

        await callApi(
            url,
            {Authorization: `Bearer ${getToken()}`},
            'post',
            body)
            .then(response => {
                // Update local state too - will need to extend context with a setPageContents to update global state
                responseHandler('success', {
                    message: `Successfully ${isNewContent ? 'created' : 'updated'} ${response?.data?.data?.page ?? " "} section`
                });
            })
            .catch(({ response }) => {
                responseHandler('error', response?.data ?? 'Something went wrong')
                if(response?.status === 401) signOut();
            });
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasUpdated = await handleContentEditOrCreation()
        if((hasPageBeenUpdated() || !selectedPage) && droppedFiles.length === 0 ){
            if(!hasUpdated) responseHandler("error", { message: "You've made no changes to the page" });
            return;
        }


        const formData = new FormData();
        if(droppedFiles.length === 1){
            formData.append("image", droppedFiles[0])
            setUpdatedPage({...updatedPage, formData})
        }
        if(Object.keys(updatedPage).length > 0){
            for(const[key, value] of Object.entries(updatedPage)){
                formData.append(key, value);
            }
        }

        callApi('/pages/edit',
            {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`
            },
            'post',
            formData)
            .then((response) => {
                setSelectedPage(null);
                setUpdatedPage(null);
                setDroppedFiles([]);
                selectRef.current.selectedIndex = 0;
                responseHandler('success', {
                    message: `Successfully updated ${response?.data?.data?.page ?? " "} page`
                });
            })
            .catch(({ response }) => {
                responseHandler('error', response?.data ?? 'Something went wrong')
                if(response?.status === 401) signOut();
            });
    }

    const handlePageSelection = ({target}) => {
        let chosenPage = pages.filter(page => page.page === target.value)[0];
        let {title, subtitle, id} = chosenPage;
        setSelectedSection(null);
        setPageSection(null);
        setSelectedPage(chosenPage);
        setUpdatedPage({
            title, subtitle, id
        })
    }

    const handleSectionSelection = ({target: {value}}) => {
        let {contents} = selectedPage;
        let chosenSection = contents.filter(section => section.id === parseInt(value))[0];
        setSelectedSection(chosenSection);
    }

    const dropdownOptions = () => {
        return pages
            ? (
                pages.map((page) => {
                    return <option
                        key={page.id}
                        value={page.page}
                    >
                        {page.title}
                    </option>
                })
            )
            : <option value="No option">No option</option>
    }

    const pageSectionOptions = () => {
        let {contents} = selectedPage;
        return contents
            ? (
                contents.map((content) => {
                    return <option
                        key={content.id}
                        value={content.id}
                    >
                        {content.section_title}
                    </option>
                })
            )
            : <option value="No option">No option</option>
    }

    return (
        <section className="admin">
            <ToastContainer />
            <Card>
                <Button
                    variant="secondary"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    Page Edit {isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </Button>
                <Collapse in={isOpen}>
                    <Card.Body>
                        <div className={selectDropdown}>
                            <select required onChange={handlePageSelection} ref={selectRef}>
                                <option selected default disabled >Select a page</option>
                                {dropdownOptions()}
                            </select>
                        </div>
                        <form name='fileUpload' onSubmit={handleSubmit}>
                            <FileUploader
                                classes="uploader"
                                children={
                                    <section className={fileContainer}>
                                        <input accept=".jpg,.png,.jpeg" type="file" name="files" />
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>
                                        <span>{droppedFiles.length > 0 ? `${droppedFiles.length} selected file(s)` : "Click to select a header for your page"}</span>
                                    </section>
                                }
                                handleChange={handleFileChange}
                                name="files"
                                types={fileTypes}
                                label='Select or drop files here'
                            />
                            <section className={inputContainer}>
                                <label htmlFor="title">Title</label>
                                <input type="text" name="title" placeholder="Title" onChange={handleChange} value={updatedPage?.title ?? ""}/>
                            </section>

                            <section className={inputContainer}>
                                <label htmlFor="subtitle">Subtitle</label>
                                <input type="text" name="subtitle" placeholder="Subtitle" onChange={handleChange} value={updatedPage?.subtitle ?? ""}/>
                            </section>
                            {selectedPage !== null &&
                                <section className={inputContainer}>
                                    <section className={pageEditContentContainer}>
                                        <label htmlFor="content">Content sections</label>
                                        {isNewContent
                                            ? <span onClick={() => setIsNewContent(!isNewContent)}><FontAwesomeIcon icon={faArrowLeft} /> Back</span>
                                            : <span onClick={() => setIsNewContent(!isNewContent)}>Add New <FontAwesomeIcon icon={faPlus} /></span>}
                                    </section>
                                    {!isNewContent
                                        ?
                                            <>
                                                {selectedPage?.contents?.length > 0 &&
                                                    <div className={selectDropdown}>
                                                        <select required onChange={handleSectionSelection} ref={selectRef}>
                                                            <option selected default disabled>Select a section</option>
                                                            {pageSectionOptions()}
                                                        </select>
                                                    </div>
                                                }
                                                {selectedSection &&
                                                    <>
                                                        <input
                                                            type="text"
                                                            name="section_title"
                                                            placeholder="Section title"
                                                            onChange={handlePageSectionChange}
                                                            value={selectedSection?.section_title ?? ""}
                                                        />
                                                        <textarea
                                                            type="text"
                                                            name="section_content"
                                                            onChange={handlePageSectionChange}
                                                            value={selectedSection?.section_content ?? ""}
                                                            rows="5"
                                                        />
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                                <input
                                                    type="text"
                                                    name="section_title"
                                                    placeholder="Section title"
                                                    onChange={handleSectionChange}
                                                    value={pageSection?.section_title ?? ""}
                                                />
                                                <textarea
                                                    type="text"
                                                    name="section_content"
                                                    placeholder="Section content"
                                                    onChange={handleSectionChange}
                                                    value={pageSection?.section_content ?? ""}
                                                    rows="5"
                                                />
                                            </>
                                    }
                                    {/* add dropdown here when not isnewcontent, add extra fields if isnewcontent*/}
                                    {/*<input type="text" name="content" placeholder="Page content" onChange={handleChange} value={updatedPage?.content ?? ""}/>*/}
                                </section>
                            }
                            <input type="submit" value={!isNewContent ? "Update" : "Create"} className="action"/>
                        </form>
                    </Card.Body>
                </Collapse>
            </Card>
        </section>
    );
}

export default PageEdit;
