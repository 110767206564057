import React, { useState, useEffect, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch, Route, Link, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "../components/contexts/AuthContext";
import { callApi } from "../services/apiService";
import { LanguageContext } from "../components/contexts/LanguageContext";
import {
    MyProfile,
    ShoppingCart,
    OrderHistory,
    PasswordReset,
    Shop,
} from "./profileComponents";
import LoadingScreen from "../components/loading-screen/LoadingScreen";
import responseHandler from "../helpers/responseHandler";
import { useScreenSizeClass } from "../helpers/media-query";
import "../css/profile.scss";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import {faStore} from "@fortawesome/free-solid-svg-icons/faStore";
import {faHistory} from "@fortawesome/free-solid-svg-icons/faHistory";
import {faUnlockAlt} from "@fortawesome/free-solid-svg-icons/faUnlockAlt";

function Profile(props, { history }) {
    const [profileDetails, setProfileDetails] = useState({});
    const [currentView, setCurrentView] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const { /*getUser, */ signOut, getToken } = useContext(AuthContext);
    const languageContext = useContext(LanguageContext);
    let screenSize = useScreenSizeClass();
    const extraSmallScreen = screenSize === "screen-x-small";
    let match =
        props?.location?.state?.match ??
        props?.location?.pathname ??
        "/profile";
    //This is checked on every render, in case it gets changed...
    /*useEffect(() => {
         let user = getUser();
         if(user && profileDetails){
             if(profileDetails.username !== user.username){
                 console.log("somet wrong")
                 signOut();
             }
             else{
                 console.log("usernames are the same")
             }
         }
     }, [getUser]);*/

    const getProfileDetails = () => {
        const additionalHeaders = {
            "Content-Language": languageContext.languageState,
            Authorization: "Bearer " + getToken(),
        };

        callApi("/profile", additionalHeaders)
            .then((response) => {
                setProfileDetails(response.data);
                // Implement notification of user here
                setIsLoading(false);
            })
            .catch(() => {
                // Need to sign user out here as well
                signOut();
                props.history.push("../login");
                // Would show error popup on login so removed it
                // responseHandler("error", response.data)
            });
    };

    useEffect(() => {
        getProfileDetails();
    }, []);

    const resetPassword = () => {
        const additionalHeaders = {
            "Content-Language": languageContext.languageState,
        };
        callApi(
            "/manage/customers/reset/" + profileDetails.id,
            additionalHeaders
        )
            .then((response) => {
                responseHandler("success", response.data);
                setCurrentView(1);
            })
            .catch(({ response }) => {
                responseHandler("error", response.data);
            });
    };

    const displayCorrectView = useCallback(
        (path) => {
            switch (path) {
                case "profile":
                    setCurrentView(0);
                    break;
                case "shop":
                    setCurrentView(1);
                    break;
                case "order-history":
                    setCurrentView(2);
                    break;
                case "reset-password":
                    setCurrentView(3);
                    break;
                case "cart":
                    setCurrentView(4);
                    break;
                default:
                    history.push("../profile");
            }
        },
        [history]
    );

    useEffect(() => {
        let path = window.location.pathname;
        let pathItems = path.split("/");
        let pathLength = pathItems.length;
        let selectedPath = pathItems[pathLength - 1];
        displayCorrectView(selectedPath);
    }, [displayCorrectView, match]);

    return (
        <main className={`profile${extraSmallScreen ? " rowDirection" : ""}`}>
            <ToastContainer />
            <LoadingScreen isLoading={isLoading} />
            {isLoading ? (
                <div className="loadingGif"></div>
            ) : (
                <>
                    <aside className="accountContainer">
                        <FontAwesomeIcon
                            icon={["fas", "user"]}
                            className="avatar"
                        />
                        <p>{profileDetails && profileDetails.username}</p>
                        <section>
                            <Link
                                to="/profile"
                                onClick={() => setCurrentView(0)}
                            >
                                <FontAwesomeIcon icon={faUser} />
                                My Profile
                            </Link>
                            <Link
                                to="/profile/cart"
                                onClick={() => setCurrentView(4)}
                            >
                                <FontAwesomeIcon
                                    icon={faShoppingCart}
                                />
                                Cart
                            </Link>
                            <Link
                                to="/profile/shop"
                                onClick={() => setCurrentView(1)}
                            >
                                <FontAwesomeIcon icon={faStore} />
                                Shop
                            </Link>
                            <Link
                                to="/profile/order-history"
                                onClick={() => setCurrentView(2)}
                            >
                                <FontAwesomeIcon icon={faHistory} />
                                Order history
                            </Link>
                            <Link
                                to="/profile/reset-password"
                                onClick={() => setCurrentView(3)}
                            >
                                <FontAwesomeIcon icon={faUnlockAlt} />
                                Reset password
                            </Link>
                        </section>
                        <Switch>
                            <Route path="/profile/shop" exact />
                            <Route path="/profile/order-history" />
                            <Route path="/profile/reset-password" />
                        </Switch>
                    </aside>
                    <section className="content">
                        {currentView === 0 && (
                            <MyProfile profile={profileDetails} />
                        )}
                        {currentView === 1 && <Shop />}
                        {currentView === 2 && (
                            <OrderHistory
                                orders={
                                    profileDetails ? profileDetails.orders : []
                                }
                            />
                        )}
                        {currentView === 3 && (
                            <PasswordReset
                                resetPassword={() => resetPassword()}
                            />
                        )}
                        {currentView === 4 && <ShoppingCart />}
                    </section>
                </>
            )}
        </main>
    );
}

export default withRouter(Profile);
