import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import './buttons.scss'

const BackToTop = ({ scrolledMore, scrollToTop }) => {
    return (
        <div className={"backToTop" + (scrolledMore ? " scrolled" : "")} onClick={scrollToTop}>
            <FontAwesomeIcon icon={faArrowUp} />
        </div>
    )
}

export default BackToTop;
