import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {faUpload} from "@fortawesome/free-solid-svg-icons/faUpload";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faWarehouse} from "@fortawesome/free-solid-svg-icons/faWarehouse";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {faImage} from "@fortawesome/free-solid-svg-icons/faImage";
import {faVideo} from "@fortawesome/free-solid-svg-icons/faVideo";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";

export const sideNavElements = [
    {
        text: "Home",
        path: "/dashboard",
        icon: <FontAwesomeIcon icon={faHome} />,
        class: "navIcon"
    },
    // {
    //     text: "Photo Upload",
    //     path: "/photo-upload",
    //     icon: <FontAwesomeIcon icon={faUpload} />,
    //     class: "navIcon"
    // },
    // {
    //     text: "Customers",
    //     path: "/customers",
    //     icon: <FontAwesomeIcon icon={faUsers} />,
    //     class: "navIcon"
    // },
    // {
    //     text: "Companies",
    //     path: "/companies",
    //     icon: <FontAwesomeIcon icon={faWarehouse} />,
    //     class: "navIcon"
    // },
    // {
    //     text: "Orders",
    //     path: "/orders",
    //     icon: <FontAwesomeIcon icon={faBook} />,
    //     class: "navIcon"
    // },
    // {
    //     text: "Settings",
    //     path: "/settings",
    //     icon: <FontAwesomeIcon icon={faCog} />,
    //     class: "navIcon"
    // },
    // {
    //     text: "Photos",
    //     path: "/photos",
    //     icon: <FontAwesomeIcon icon={faImage} />,
    //     class: "navIcon"
    // },
    // {
    //     text: "Videos",
    //     path: "/videos",
    //     icon: <FontAwesomeIcon icon={faVideo} />,
    //     class: "navIcon"
    // },
    {
        text: "Logout",
        path: "../..",
        icon: <FontAwesomeIcon icon={faSignOutAlt} />,
        class: "navIcon",
    },
]
