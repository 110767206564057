import React from "react";
import "../../css/dashboard.scss";
// import DailyVisitorWidget from "../../components/widgets/DailyVisitors.js";
// import Top5PagesWidget from "../../components/widgets/Top5Pages.js";
// import MostInteractiveUsersWidget from "../../components/widgets/MostInteractiveUsers.js";
// import DailyUsersWidget from "../../components/widgets/DailyUsers.js";
// import DailyClicksWidget from "../../components/widgets/DailyClicks.js";
// import PhotoUpload from "./PhotoUpload";
import {content,widgets} from "../../css/admin/admin.module.scss";
import clsx from "clsx";
import PageEdit from "./PageEdit";
import AlbumEdit from "./AlbumEdit";
import Invitation from "./companyComponents/Invitation";
import CompanyLogic from "./companyComponents/CompanyLogic";

function Dashboard(){
    const {
        companies,
        selectedCompany,
        invitations,
        pendingInvitation,
        handlePendingInvSelection,
        handleScroll,
        handleEmail,
        handleEnterPress,
        handleCompanySelection,
        modifyInvitation,
        setInvitations,
        setCompanies
    } = CompanyLogic();

    return (
        <main className="admin">
            <section className={clsx(content,widgets)}>
                <AlbumEdit />
                <PageEdit />
                {(!invitations || !companies)
                    ? <div className="loadingGif"></div>
                    : <Invitation
                        handleEmail={handleEmail}
                        handleEnterPress={handleEnterPress}
                        invitations={invitations}
                        pendingInvitation={pendingInvitation}
                        handleScroll={handleScroll}
                        handlePendingInvSelection={handlePendingInvSelection}
                        modifyInvitation={modifyInvitation}
                        setInvitations={setInvitations}
                        selectedCompany={selectedCompany}
                        handleCompanySelection={handleCompanySelection}
                        companies={companies}
                        setCompanies={setCompanies}
                    />
                }
                {/*<DailyVisitorWidget />*/}
                {/*<Top5PagesWidget />*/}
                {/*<MostInteractiveUsersWidget />*/}
                {/*<DailyUsersWidget />*/}
                {/*<DailyClicksWidget />*/}
            </section>
        </main>
    );
}

export default Dashboard;
