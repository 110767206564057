import React, {useContext, useEffect} from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
// import Photos from '../../pages/adminPanel/Photos';
// import Videos from '../../pages/adminPanel/Videos';
// import Settings from '../../pages/adminPanel/Settings';
// import Customers from '../../pages/adminPanel/Customers';
// import Companies from '../../pages/adminPanel/Companies';
// import Orders from '../../pages/adminPanel/Orders';
import Dashboard from '../../pages/adminPanel/Dashboard';
import PhotoUpload from '../../pages/adminPanel/PhotoUpload';
import Login from '../../pages/Login';
import { AuthContext } from '../../components/contexts/AuthContext';
import {callApi} from "../../services/apiService";

function AdminRouteControl() {
    const { signOut, getUser, getToken } = useContext(AuthContext);

    const validateLogin = () => {
        let {role} = getUser()
        let bearer = getToken()
        let header = {
            "Authorization": `Bearer ${bearer}`
        }
        callApi("/login/validate",header,"post", {r: role})
            .catch(({response}) => {
                console.log(response)
                if(response.status === 401) signOut()
            } )
    }

    useEffect(() => {
        validateLogin()
    }, [])
    return (
        <Switch>
            <Route exact path='/admin/dashboard' component={Dashboard}></Route>
            {/*<Route path='/admin/photo-upload' component={PhotoUpload}></Route>*/}
            {/*<Route path='/admin/settings' component={Settings}></Route>*/}
            {/*<Route path='/admin/customers' component={Customers}></Route>*/}
            {/*<Route path='/admin/companies' component={Companies}></Route>*/}
            {/*<Route path='/admin/photos' component={Photos}></Route>*/}
            {/*<Route path='/admin/videos' component={Videos}></Route>*/}
            {/*<Route path='/admin/orders' component={Orders}></Route>*/}
            <Route path='/admin/logout' component={Login}></Route>
            <Route render={() => <Redirect to="/admin/dashboard" />} />
        </Switch>
    );
}

export default AdminRouteControl;
