const CartLogic = () => {
    const total_price = (packages) => {
        let sum = packages.reduce((partial_sum, a) => {
            return partial_sum + parseInt(a.price)
        }, 0);
        return sum;
    }

    const countPacks = (packages) => {
        let newArray = JSON.parse(JSON.stringify(packages));
        let unique = [];
        let isInUnique;

        newArray.map(pack => {

            isInUnique = unique.some(uPack => uPack.id === pack.id);
            if (!isInUnique) {
                pack.count = 1;
                unique.push(pack);
            }
            else {
                unique = unique.map(
                    uPack => uPack.id === pack.id
                        ? { ...uPack, count: uPack.count + 1 }
                        : uPack
                );
            };
        });

        return unique;
    }

    return {
        total_price,
        countPacks
    }
}
export default CartLogic;
