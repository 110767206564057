

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function errorHandler(errors, message) {
    try{
        let localErrors;
        if(errors) {
            let keys = Object.keys(errors).length;
            keys > 0
                ? localErrors = Object.keys(errors).map((error) => toast.error(errors[error][0]))
                : localErrors = toast.error(message)
            return localErrors;
        }
        return toast.error(message);
    }
    // Fallback
    catch(e){
        console.log(e);
    }
}

function successHandler(message) {
    try{
        return toast.success(message);
    }
    // Fallback
    catch(e) {
        console.log(e)
    }
}

export default function responseHandler(type, payload){
    if(payload){
        let {errors, message} = payload;
        switch(type){
            case "error":
                errorHandler(errors, message)
                break;
            case "success":
                successHandler(message);
                break;
            default:
                break;
        }
    }
}
