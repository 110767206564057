import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faYoutube} from "@fortawesome/free-brands-svg-icons/faYoutube";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons/faFacebookF";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import { socials } from "./socials.module.scss";
const Socials = (props) => {
    let {isText} = props;
    return (
        <article className={socials}>
            <a href="https://www.youtube.com/channel/UCkVJ7_Dg2dW77b2JL2oVhWw" target="_blank" rel="noreferrer noopener">
                <FontAwesomeIcon icon={faYoutube} />
                {isText && <p>Art Force Studios</p>}
            </a>

            <a href="https://www.instagram.com/art_force_studios/" target="_blank" rel="noreferrer noopener">
                <FontAwesomeIcon icon={faInstagram} />
                {isText && <p>art_force_studios</p>}
            </a>

            <a href="https://www.facebook.com/artforcestudio" target="_blank" rel="noreferrer noopener">
                <FontAwesomeIcon icon={faFacebookF} />
                {isText && <p>Artforcestudio</p>}
            </a>

            <a href="mailto:hello@artforcestudios.co.uk?subject=Enquiry">
                <FontAwesomeIcon icon={faEnvelope} />
                {isText && <p>hello@artforcestudios.co.uk</p>}
            </a>

            <a href="tel:07513131310">
                <FontAwesomeIcon icon={faPhone} />
                {isText && <p>07513 131310</p>}
            </a>

        </article>
    )
}

export default Socials;
