export const header = {
    'en': {
        'home': "Home",
        'videography': "Videography",
        'photography': "Photography",
        'about': "About",
        "contact": "Contact",
        "partners": "Partners",
        "login": "Login",
        "prices": "Prices",
        "dashboard": "Dashboard",
        "settings": "Settings",
        "videos": "Videos",
        "photos": "Photos",
        "customers": "Customers",
    },
    'hu': {
        'home': "Fooldal",
        'videography': "Videografia",
        'photography': "Fotografia",
        "about": "Rólunk",
        "contact": "Elérhetoseg",
        "partners": "Partnerek",
        "login": "Belépés",
        "prices": "Árak",
        "dashboard": "Irányítópult",
        "settings": "Beállítások",
        "videos": "Videók",
        "photos": "Fotók",
        "customers": "Kliensek",
    }
}